import {CreateGuesser, EditGuesser, FieldGuesser, InputGuesser, ListGuesser, ShowGuesser} from "@api-platform/admin";
import {Typography} from "@mui/material";
import {Link} from 'react-router-dom';
import {useWatch} from 'react-hook-form';
import {
    List,
    Datagrid,
    DateField,
    ReferenceManyField,
    ReferenceManyCount,
    TextField,
    BooleanField,
    ArrayField,
    SingleFieldList,
    FunctionField,
    ChipField,
    EditButton,
    ReferenceField,
    BulkDeleteButton,
    Button,
    useResourceContext,
    TopToolbar,
    SelectColumnsButton,
    CreateButton,
    ExportButton,
    FilterButton,
    DatagridConfigurable,
    TextInput,
    ReferenceInput,
    AutocompleteInput,
    ShowButton,
    ImageField,
    ImageInput,
    BooleanInput,
    NullableBooleanInput,
    SelectInput,
    FormDataConsumer, SearchInput, ArrayInput, SimpleFormIterator, SelectArrayInput, ReferenceArrayField
} from "react-admin";
import {
    Card,
    Toolbar,
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
} from '@mui/material';
import {
    Admin,
    Resource,
    useEditContext,
    useShowContext,
    useRecordContext,
    TabbedForm,
    Show,
    TabbedShowLayout
} from 'react-admin';
import React, {useEffect, useState} from "react";
import {Communaute} from "../types/communaute";

const equipeFilters = [
    <SearchInput source="q" alwaysOn/>,
    <ReferenceInput source="communaute_id" reference="communautes" name={"Communaute"}>
        <AutocompleteInput
            optionText={(choice) =>
                choice?.id // the empty choice is { id: '' }
                    ? `${choice.titre}`
                    : ''
            }
            name={"communaute"}/>
    </ReferenceInput>,
    <TextInput name={"nom"} source={"nom"}/>,
];

export const EquipeList = props => (
    <List {...props} filters={equipeFilters}>
        <DatagridConfigurable rowClick={"show"}>
            <ReferenceField reference={"communautes"} source={"communaute"} label={"Communauté"}>
                <TextField source="titre"/>
            </ReferenceField>
            <ReferenceField reference={"equipes"} source={"equipeParent"} label={"Equipe parent"}>
                <TextField source="titre"/>
            </ReferenceField>
            <FieldGuesser source={"nom"}/>
            <ImageField source={"src"}/>
            <BooleanField source={"structurante"}/>
            <BooleanField source={"cloisonnante"}/>
        </DatagridConfigurable>
    </List>
);

const EquipeParentInput = props => {
    const communaute = useWatch({name: 'communaute'});
    return (
        <FormDataConsumer>
            {({formData, ...rest}) => (communaute !== undefined && communaute !== null) &&
                <ReferenceInput reference="equipes" filter={{communaute: communaute}} {...props}>
                    <AutocompleteInput
                        optionText={(choice) =>
                            choice?.id // the empty choice is { id: '' }
                                ? `${choice.nom}`
                                : ''
                        }
                        label="Equipe parent"/>
                </ReferenceInput>
            }
        </FormDataConsumer>
    );
}

const CommunauteInput = props => {
    return (
        <ReferenceInput reference="communautes" {...props} >
            <AutocompleteInput
                optionText={(choice) =>
                    choice?.id // the empty choice is { id: '' }
                        ? `${choice.titre}`
                        : ''
                }
                label="Communauté"/>
        </ReferenceInput>
    );
}

const CloisonnanteInput = props => {
    return (
        <BooleanInput defaultValue={false} {...props} />
    );
}

const StructuranteInput = props => {
    return (
        <BooleanInput defaultValue={true} {...props} />
    );
}

export const EquipeCreate = props => (
    <CreateGuesser {...props}>
        <CommunauteInput source="communaute"/>
        <EquipeParentInput source="equipeParent"/>
        <TextInput source={"nom"}/>
        <StructuranteInput source={"structurante"}/>
        <CloisonnanteInput source={"cloisonnante"}/>
        {/*<ImageInput source={"logoFile"} />*/}
        <ImageInput multiple={false}
                    source="logoFile"
                    label="Related pictures"
                    accept="image/*">
            <ImageField source="src" title="logo"/>
        </ImageInput>
        <MembresInput source="membres"/>
    </CreateGuesser>
);

const DroitTypes = [
    {
        id: "UTILISATEUR",
        name: "Utilisateur"
    },
    {
        id: "MANAGER",
        name: "Manager"
    },
    {
        id: "REDACTEUR",
        name: "Rédacteur"
    }
];

const MembresInput = props => {
    const communaute = useWatch({name: 'communaute'});
    return (
        <ArrayInput {...props}>
            <SimpleFormIterator inline fullWidth>
                <ReferenceInput
                    source="utilisateur"
                    reference={"utilisateurs"}
                    fullWidth
                    inputProps={{width: '400px'}}
                    filter={communaute !== undefined && communaute !== null ? {communaute: communaute} : {}}
                />
                <TextInput source="nomPoste"/>
                <SelectArrayInput
                    source={"roles"}
                    choices={DroitTypes}
                />
            </SimpleFormIterator>
        </ArrayInput>
    )
}

const MembresField = props => {
    return (
        <ArrayField {...props}>
            <Datagrid>
                <ReferenceField
                    source="utilisateur"
                    reference={"utilisateurs"}
                    fullWidth
                    inputProps={{width: '400px'}}
                />
                <TextField
                    source="nomPoste"/>
                <ArrayField
                    source={"roles"}
                    choices={DroitTypes}>
                    <SingleFieldList>
                        <FunctionField render={(record) => (
                            <ChipField record={{name: record}} source="name"/>
                        )}/>
                    </SingleFieldList>
                </ArrayField>
            </Datagrid>
        </ArrayField>
    )
}


export const EquipeEdit = props => {
    const record = useRecordContext();

    return <EditGuesser {...props}>
        <CommunauteInput source="communaute"/>
        <EquipeParentInput source="equipeParent"/>
        <TextInput source={"nom"}/>
        <StructuranteInput source={"structurante"}/>
        <CloisonnanteInput source={"cloisonnante"}/>
        <ImageInput multiple={false}
                    source="logoFile"
                    label="Related pictures"
                    accept="image/*">
            <ImageField source="src" title="logo"/>
        </ImageInput>
        <MembresInput source="membres"/>
    </EditGuesser>
};

export const EquipeShow = props => {
    return (
        <Show {...props}>
           <MyEquipeShow/>
        </Show>
    );
};

const EquipeFillesField = props => {
    return (
        <ReferenceArrayField reference="equipes" source="equipes" label={"Equipe filles"}>
            <Datagrid rowClick={"show"} bulkActionButtons={false}>
                <TextField source="nom" />
            </Datagrid>
        </ReferenceArrayField>
    );
};


 const MyEquipeShow = props => {
    const record = useRecordContext();
    if (!record) return null;
    return (
            <TabbedShowLayout>
                <TabbedShowLayout.Tab label="Equipe">
                    <ReferenceField source={"communaute"} reference={"record"}>
                        <TextField source="titre"/>
                    </ReferenceField>
                    <FieldGuesser source="nom"/>
                    <FieldGuesser source={"logo"} />

                    {record && record.equipeParent &&
                        <ReferenceField source={"equipeParent"}
                                        reference={"record"}
                                        label={"Equipe parent"}
                                        //TODO arriver a faire le lien vers la page de détail de l'équipe parent
                                        link={(record, reference) => `/equipes`}
                                        >
                            <TextField source="nom"/>
                        </ReferenceField>}

                    {record && record.equipes && <EquipeFillesField source="Equipes filles"/>}

                </TabbedShowLayout.Tab>
                <TabbedShowLayout.Tab label="Membres">
                    <MembresField source="membres"/>
                </TabbedShowLayout.Tab>
            </TabbedShowLayout>
    );
};