import { TranslationMessages } from "react-admin";
import frenchMessages from "ra-language-french";

const customFrenchMessages = {
    ...frenchMessages,
    pos: {
        edit: "Editer"
    }
};

export default customFrenchMessages;
