import {CreateGuesser, EditGuesser, FieldGuesser, InputGuesser, ListGuesser, ShowGuesser} from "@api-platform/admin";
import HorizontalRule from '@tiptap/extension-horizontal-rule';
import {
    Edit,
    BooleanInput,
    AutocompleteInput,
    BooleanField,
    Count,
    SimpleForm,
    DatagridConfigurable,
    DateField,
    DateInput,
    ExportButton,
    FilterButton,
    List,
    Show,
    NullableBooleanInput,
    NumberField,
    ReferenceField,
    ReferenceInput,
    SearchInput,
    SelectColumnsButton,
    TextField,
    TextInput,
    SelectInput,
    TopToolbar,
    useListContext,
    ShowButton,
    ListButton,
    useRecordContext,
    useUpdate,
    useDataProvider,
    Datagrid,
    CreateButton,
    EditButton,
    ReferenceManyField,
    ImageField,
    ImageInput, FormDataConsumer, Labeled,
    TabbedShowLayout,
    ReferenceArrayInput,
    ArrayField,
    SingleFieldList,
    ChipField,
    ReferenceArrayField
} from 'react-admin';
import { useMutation } from 'react-query';
import {
    DefaultEditorOptions,
    RichTextInput,
    RichTextInputToolbar,
    LevelSelect,
    FormatButtons,
    AlignmentButtons,
    ListButtons,
    LinkButtons,
    QuoteButtons,
    ClearButtons,
    useTiptapEditor,
} from 'ra-input-rich-text';
import {Button, Typography} from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import {useWatch} from "react-hook-form";
import React from "react";

export const SignalementCategorieList = props => (
    <ListGuesser rowClick={"edit"} {...props}>
        <ReferenceField reference={"communautes"} source={"communaute"} label={"Communauté"}>
            <TextField source="titre"/>
        </ReferenceField>
        <FieldGuesser source={"titre"} />
        <FieldGuesser source={"isPublic"} label={"Public"} />
        <ReferenceArrayField source="utilisateursTraiteSignalement" label={"Membres"} reference={"utilisateurs"}>
            <SingleFieldList>
                <ChipField source="name" />
            </SingleFieldList>
        </ReferenceArrayField>
    </ListGuesser>
);

const CommunauteInput = props => (
    <ReferenceInput reference="communautes" name={"Communaute"} {...props}>
        <AutocompleteInput
            optionText={(choice) =>
                choice?.id // the empty choice is { id: '' }
                    ? `${choice.titre}`
                    : ''
            }
            name={"communaute"} {...props} />
    </ReferenceInput>
);

const UtilisateurInput = props => {
    const communaute = useWatch({name: 'communaute'});
    return (
        <FormDataConsumer>
            {({formData, ...rest}) => (communaute !== undefined && communaute !== null) &&
                <ReferenceArrayInput source="utilisateursTraiteSignalement" reference="utilisateurs" filter={{communaute: communaute}} {...props} />
            }
        </FormDataConsumer>
)};

export const SignalementCategorieCreate = props => (
    <CreateGuesser {...props}>
        <CommunauteInput source="communaute_id" />
        <TextInput source={"titre"} fullWidth={true} />
        <UtilisateurInput source="utilisateursTraiteSignalement" />
        <BooleanInput source={"isPublic"} label={"Public ?"} />
    </CreateGuesser>
);

export const SignalementCategorieEdit = props => (
    <EditGuesser {...props}>
        <CommunauteInput source="communaute_id" disabled />
        <TextInput source={"titre"} fullWidth={true} />
        <UtilisateurInput source="utilisateursTraiteSignalement" />
        <BooleanInput source={"isPublic"} label={"Public ?"} />
    </EditGuesser>
);