import {CreateGuesser, EditGuesser, FieldGuesser, InputGuesser, ListGuesser, ShowGuesser} from "@api-platform/admin";
import HorizontalRule from '@tiptap/extension-horizontal-rule';
import {
    Edit,
    BooleanInput,
    AutocompleteInput,
    BooleanField,
    Count,
    SimpleForm,
    DatagridConfigurable,
    DateField,
    DateInput,
    ExportButton,
    FilterButton,
    List,
    Show,
    NullableBooleanInput,
    NumberField,
    ReferenceField,
    ReferenceInput,
    SearchInput,
    SelectColumnsButton,
    TextField,
    TextInput,
    SelectInput,
    TopToolbar,
    useListContext,
    ShowButton,
    ListButton,
    useRecordContext,
    useUpdate,
    useDataProvider,
    Datagrid,
    CreateButton,
    EditButton,
    ReferenceManyField,
    ImageField,
    ImageInput, FormDataConsumer, Labeled,
    TabbedShowLayout, ReferenceArrayInput, ReferenceArrayField, SingleFieldList, ChipField
} from 'react-admin';
import { useMutation } from 'react-query';
import {
    DefaultEditorOptions,
    RichTextInput,
    RichTextInputToolbar,
    LevelSelect,
    FormatButtons,
    AlignmentButtons,
    ListButtons,
    LinkButtons,
    QuoteButtons,
    ClearButtons,
    useTiptapEditor,
} from 'ra-input-rich-text';
import {Button, Typography} from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import {useWatch} from "react-hook-form";
import React from "react";

const CibleListActions = () => (
    <TopToolbar>
        <SelectColumnsButton />
        <FilterButton />
        <CreateButton />
        <ExportButton />
    </TopToolbar>
);

const filters = [
    <ReferenceInput source="communaute_id" reference="communautes" name={"Communaute"}>
        <AutocompleteInput
            optionText={(choice) =>
                choice?.id // the empty choice is { id: '' }
                    ? `${choice.titre}`
                    : ''
            }
            name={"communaute"}/>
    </ReferenceInput>
];

export const CibleList = props => (
    <List
        filters={filters}
        actions={<CibleListActions />}
        {...props}>
        <DatagridConfigurable rowClick={"show"}>
            <ReferenceField reference={"communautes"} source={"communaute"} label={"Communauté"}>
                <TextField source="titre"/>
            </ReferenceField>
            <FieldGuesser source={"inclureCommunaute"} />
            <ReferenceArrayField source="utilisateurIds" label={"Utilisateurs"} reference={"utilisateurs"}>
                <SingleFieldList>
                    <ChipField source="name" />
                </SingleFieldList>
            </ReferenceArrayField>
            <ReferenceArrayField source="equipeIds" label={"Equipes"} reference={"equipes"}>
                <SingleFieldList>
                    <ChipField source="nom" />
                </SingleFieldList>
            </ReferenceArrayField>
        </DatagridConfigurable>
    </List>
);

export const CibleShow = props => (
    <ShowGuesser {...props}>
            <FieldGuesser source={"communaute"} />
            <FieldGuesser source={"inclureCommunaute"} />
            <ReferenceArrayField source="utilisateurIds" label={"Utilisateurs"} reference={"utilisateurs"}>
                <SingleFieldList>
                    <ChipField source="name" />
                </SingleFieldList>
            </ReferenceArrayField>
            <ReferenceArrayField source="equipeIds" label={"Equipes"} reference={"equipes"}>
                <SingleFieldList>
                    <ChipField source="nom" />
                </SingleFieldList>
            </ReferenceArrayField>
    </ShowGuesser>
);

const UtilisateursButton = props => {
    const communaute = useWatch({ name: 'communaute' });
    return (
        <FormDataConsumer>
            {({formData, ...rest}) => (communaute !== undefined && communaute !== null) &&
                <ReferenceArrayInput source="utilisateurIds" reference="utilisateurs" filter={{communaute: communaute}} {...props} />
            }
        </FormDataConsumer>
    );
}

const EquipesButton = props => {
    const communaute = useWatch({ name: 'communaute' });
    return (
        <FormDataConsumer>
            {({formData, ...rest}) => (communaute !== undefined && communaute !== null) &&
                <ReferenceArrayInput source="equipeIds" reference="equipes" filter={{communaute: communaute}} {...props} />
            }
        </FormDataConsumer>
    );
}

export const CibleEdit = props => (
    <EditGuesser {...props}>
        <InputGuesser source={"communaute"} />
        <BooleanInput source={"inclureCommunaute"} />
        <UtilisateursButton source={"utilisateurIds"} />
        <EquipesButton source={"equipeIds"} />
    </EditGuesser>
);

export const CibleCreate = props => (
    <CreateGuesser {...props}>
        <InputGuesser source={"communaute"} />
        <BooleanInput source={"inclureCommunaute"} />
        <UtilisateursButton source={"utilisateurIds"} />
        <EquipesButton source={"equipeIds"} />
    </CreateGuesser>
);