import jwtDecode from 'jwt-decode';
import { ENTRYPOINT } from '../config';

export default {
    // @ts-ignore
    login: ({ username, password }) => {
        const request = new Request(`${ENTRYPOINT}/login_check`, {
            method: 'POST',
            body: JSON.stringify({ username: username, password }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });
        return fetch(request)
            .then((response) => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(({ token }) => {
                // On vérifie que le user a au minimum le rôle de ROLE_ADMIN
                // On déclenche une requête HTTP sur /me pour avoir les permissions
                const meRequest = new Request(`${ENTRYPOINT}/me`, {
                    method: 'GET',
                    headers: new Headers({
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }),
                });
                return fetch(meRequest).then(async (response) => {
                    if (response.status < 200 || response.status >= 300) {
                        throw new Error(response.statusText);
                    }
                    if (await response.json().then(({roles}) => (roles.includes('ROLE_ADMIN') || roles.includes('ROLE_SUPER_ADMIN')))) {
                        localStorage.setItem('token', token);
                        return token;
                    }
                    throw new Error('Unauthorized');
                })
            });
    },
    logout: () => {
        localStorage.removeItem('token');
        return Promise.resolve();
    },
    checkAuth: () => {
        try {
            if (
                !localStorage.getItem('token') ||
                new Date().getTime() / 1000 >
                // @ts-ignore
                jwtDecode(localStorage.getItem('token'))?.exp
            ) {
                return Promise.reject();
            }
            return Promise.resolve();
        } catch (e) {
            // override possible jwtDecode error
            return Promise.reject();
        }
    },
    checkError: (err: { status: any; response: { status: any; }; }) => {
        if ([401, 403].includes(err?.status || err?.response?.status)) {
            localStorage.removeItem('token');
            return Promise.reject();
        }
        return Promise.resolve();
    },
    getPermissions: () => Promise.resolve(),
};