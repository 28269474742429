import {EditGuesser, FieldGuesser, InputGuesser, ListGuesser, ShowGuesser} from "@api-platform/admin";
import {
    Datagrid,
    DateField,
    DateInput,
    ReferenceManyField,
    ReferenceManyCount,
    TextField,
    BooleanField,
    ArrayField,
    EmailField,
    TextInput,
    ArrayInput,
    SimpleFormIterator,
    SelectArrayInput,
    Show,
    TabbedShowLayout,
    Admin,
    Resource,
    useEditContext,
    useShowContext,
    useRecordContext,
    TabbedForm,
    EditButton,
    ReferenceField,
    SingleFieldList,
    FunctionField,
    ChipField,
    Button,
    List,
    ReferenceInput,
    AutocompleteInput,
    SelectInput,
    SearchInput,
    ImageField,
    ImageInput,
    FormDataConsumer,
    useDataProvider, useRefresh, BooleanInput, TimeInput, FormTab, Edit
} from "react-admin";
import {Link} from "react-router-dom";
import {useMutation} from "react-query";
import SendIcon from "@mui/icons-material/Send";
import React from "react";

const filters = [
    <SearchInput source="q" alwaysOn/>
];

export const UtilisateurList = props => (
    <List{...props} filters={filters}>
        <Datagrid rowClick={"show"}>
            <FieldGuesser source={"email"}/>
            <FieldGuesser source={"telephone"}/>
            <FieldGuesser source={"nom"}/>
            <FieldGuesser source={"prenom"}/>
            <FieldGuesser source={"dateNaissance"}/>
            <FieldGuesser source={"name"}/>
        </Datagrid>
    </List>
);

const UtilisateurTitle = () => {
    const record = useRecordContext();
    // the record can be empty while loading
    if (!record) return null;
    return <span>Utilisateur "{record.name}"</span>;
};

const UtilisateurShowTab = props => {
    const record = useRecordContext();
    return <TabbedShowLayout>
        <TabbedShowLayout.Tab label="summary">
            <FieldGuesser source={"email"}/>
            <FieldGuesser source={"telephone"}/>
            <FieldGuesser source={"nom"}/>
            <FieldGuesser source={"prenom"}/>
            <FieldGuesser source={"dateNaissance"}/>
            <ImageField source={"avatar"}/>
            <ImageField source={"couverture"}/>
            <FieldGuesser source={"name"}/>
            <FieldGuesser source={"numeroTelephoneMasque"}/>
            <FieldGuesser source={"emailMasque"}/>
            <FieldGuesser source={"dateNaissanceMasque"}/>
            <FieldGuesser source={"anneeNaissanceMasque"}/>
            <FieldGuesser source={"horairesNotification"}/>
            <FieldGuesser source={"timezone"}/>
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="body" path="body">

        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab
            label="Communautes"
            path="communaute"
            count={
                <ReferenceManyCount
                    reference="droits"
                    target="utilisateur"
                    sx={{lineHeight: 'inherit'}}
                />
            }>
            if(record != undefined && record.id != undefined){
            <Button
                variant="raised"
                component={Link}
                to={{
                    pathname: `/droits/create`,
                    search: `?source=${JSON.stringify({utilisateur: record.id})}`,
                }} //
                label="Ajouter à une communauté"
                title="Ajouter à une communauté"
            ></Button>
        }
            <ReferenceManyField reference="droits" target="utilisateur" label={false}>
                <Datagrid>
                    <FieldGuesser source="communaute"/>
                    <ArrayField source="roles">
                        <SingleFieldList>
                            <FunctionField render={(record) => (
                                <ChipField record={{name: record}} source="name"/>
                            )}/>
                        </SingleFieldList>
                    </ArrayField>
                    <EditButton/>
                </Datagrid>
            </ReferenceManyField>
        </TabbedShowLayout.Tab>

        <TabbedShowLayout.Tab
            label="Demandes"
            path="demande"
            count={
                <ReferenceManyCount
                    reference="utilisateur_demandes"
                    target="utilisateur"
                    sx={{lineHeight: 'inherit'}}
                />
            }>
            if(record != undefined && record.id != undefined){
            <Button
                variant="raised"
                component={Link}
                to={{
                    pathname: `/utilisateur_demandes/create`,
                    search: `?source=${JSON.stringify({utilisateur: record.id})}`,
                }} //
                label="Ajouter à une demande"
                title="Ajouter à une demande"
            ></Button>
        }
            <ReferenceManyField reference="utilisateur_demandes" target="utilisateur" label={false}>
                <Datagrid>
                    <FieldGuesser source={"code"}/>
                    <FieldGuesser source={"date"}/>
                    <FieldGuesser source={"dateValidite"}/>
                    <FieldGuesser source={"nombreTentative"}/>
                    <FieldGuesser source={"type"}/>
                    <FieldGuesser source={"data"}/>
                    <EditButton/>
                </Datagrid>
            </ReferenceManyField>
        </TabbedShowLayout.Tab>

        <TabbedShowLayout.Tab label="Equipes" path="equipe">

        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab
            label="Devices"
            path="devices"
            count={
                <ReferenceManyCount
                    reference="devices"
                    target="utilisateur"
                    sx={{lineHeight: 'inherit'}}
                />
            }>
            <ReferenceManyField reference="devices" target="utilisateur" label={false}>
                <Datagrid>
                    <TextField source="identifier"/>
                    <BooleanField source="status"/>
                    <TextField source="type"/>
                    <EditButton/>
                </Datagrid>
            </ReferenceManyField>
        </TabbedShowLayout.Tab>
    </TabbedShowLayout>
}

export const UtilisateurShow = props => {
    return (
        <Show {...props}>
            <UtilisateurShowTab/>
        </Show>
    )
};

const SupprimerAvatarBouton = () => {
    const record = useRecordContext();
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const {mutate, isLoading} = useMutation(
        () => dataProvider.update(
            `utilisateurs`,
            {id: record.id, data: {deleteAvatar: true}, previousData: record}
        ).then(
            () => refresh()
        )
    );


    return <Button
        color="primary"
        size={"small"}
        sx={{mb: 3}}
        onClick={() => mutate()}
        disabled={isLoading}> Supprimer l'avatar</Button>;
};

const SupprimerCouvertureBouton = () => {
    const record = useRecordContext();
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const {mutate, isLoading} = useMutation(
        () => dataProvider.update(
            `utilisateurs`,
            {id: record.id, data: {deleteCouverture: true}, previousData: record}
        ).then(
            () => refresh()
        )
    );

    return <Button
        color="primary"
        size={"small"}
        sx={{mb: 3}}
        onClick={() => mutate()}
        disabled={isLoading}> Supprimer l'image de couverture</Button>;
};

export const UtilisateurEdit = props => (
    <Edit {...props}>
        <TabbedForm>
            <TabbedForm.Tab label="Informations">

                <TextInput source={"email"} type={"email"}/>
                <TextInput source={"telephone"}/>
                <TextInput source={"nom"}/>
                <TextInput source={"prenom"}/>
                <DateInput sx={{mb: 3}} source={"dateNaissance"}/>
                <ImageField source={"avatar"}/>
                <FormDataConsumer>
                    {({
                          formData,
                          scopedFormData,
                          getSource,
                          ...rest
                      }) => (formData.avatar !== null) ? <SupprimerAvatarBouton/> : null}

                </FormDataConsumer>
                <ImageInput multiple={false}
                            source={"file"}
                            label="Changer d'avatar"
                            accept="image/*">
                    <ImageField source="src"/>
                </ImageInput>
                <ImageField source={"couverture"}/>
                <FormDataConsumer>
                    {({
                          formData,
                          scopedFormData,
                          getSource,
                          ...rest
                      }) => (formData.couverture !== null) ? <SupprimerCouvertureBouton/> : null}

                </FormDataConsumer>
                <ImageInput multiple={false}
                            source={"couvertureFile"}
                            label="Changer d'image de couverture"
                            accept="image/*">
                    <ImageField source="src"/>
                </ImageInput>
                <SelectArrayInput source={"roles"} choices={
                    [
                        {
                            id: "ROLE_SUPER_ADMIN",
                            name: "Super Admin"
                        },
                        {
                            id: "ROLE_ADMIN",
                            name: "Admin"
                        },
                        {
                            id: "ROLE_USER",
                            name: "Utilisateur"
                        }
                    ]
                }/>
                <BooleanInput source={"numeroTelephoneMasque"}/>
                <BooleanInput source={"emailMasque"}/>
                <BooleanInput source={"dateNaissanceMasque"}/>
                <BooleanInput source={"anneeNaissanceMasque"}/>
            </TabbedForm.Tab>
            <TabbedForm.Tab label={"Horaires de notification"}>
                <TextInput name={'timezone'} source={"timezone"} label={"Fuseau horaire"}></TextInput>
                    <ArrayInput label={"Lundi"} source={"horairesNotification.Monday"}>
                        <SimpleFormIterator disableRemove={true} disableReordering={true} >
                            <TextInput />
                        </SimpleFormIterator>
                    </ArrayInput>

                    <ArrayInput label={"Mardi"} source="horairesNotification.Tuesday">
                        <SimpleFormIterator disableRemove={true} disableReordering={true} >
                            <TextInput />
                        </SimpleFormIterator>
                    </ArrayInput>

                <ArrayInput label={"Mercredi"} source="horairesNotification.Wednesday">
                    <SimpleFormIterator disableRemove={true} disableReordering={true} >
                        <TextInput />
                    </SimpleFormIterator>
                </ArrayInput>


                <ArrayInput label={"Jeudi"} source="horairesNotification.Thursday">
                    <SimpleFormIterator disableRemove={true} disableReordering={true} >
                        <TextInput />
                    </SimpleFormIterator>
                </ArrayInput>


                <ArrayInput label={"Vendredi"} source="horairesNotification.Friday">
                    <SimpleFormIterator disableRemove={true} disableReordering={true} >
                        <TextInput />
                    </SimpleFormIterator>
                </ArrayInput>


                <ArrayInput label={"Samedi"} source="horairesNotification.Saturday">
                    <SimpleFormIterator disableRemove={true} disableReordering={true} >
                        <TextInput />
                    </SimpleFormIterator>
                </ArrayInput>


                <ArrayInput label={"Dimanche"} source="horairesNotification.Sunday">
                    <SimpleFormIterator disableRemove={true} disableReordering={true} >
                        <TextInput />
                    </SimpleFormIterator>
                </ArrayInput>

            </TabbedForm.Tab>
        </TabbedForm>

    </Edit>
);