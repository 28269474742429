import {CreateGuesser, EditGuesser, FieldGuesser, InputGuesser, ShowGuesser} from "@api-platform/admin";
import {
    Datagrid,
    TextInput,
    List,
    ReferenceInput,
    AutocompleteInput,
    SearchInput, ReferenceField, TextField,
} from "react-admin";
import React from "react";

const filters = [
    <SearchInput source="q" alwaysOn/>,
    <ReferenceInput source="communaute_id" reference="communautes" name={"Communaute"}>
        <AutocompleteInput
            optionText={(choice) =>
                choice?.id // the empty choice is { id: '' }
                    ? `${choice.titre}`
                    : ''
            }
            name={"communaute"}/>
    </ReferenceInput>,
    <TextInput source={"titre"} />,
];

export const SondageCategorieList = props => (
    <List{...props} filters={filters}>
        <Datagrid rowClick={"show"}>
            <ReferenceField reference={"communautes"} source={"communaute"} label={"Communauté"}>
                <TextField source="titre"/>
            </ReferenceField>
            <FieldGuesser source={"titre"}/>
            <FieldGuesser source={"couleur"}/>
        </Datagrid>
    </List>
);
export const SondageCategorieShow = props => (
    <ShowGuesser {...props}>
        <ReferenceField reference={"communautes"} source={"communaute"} label={"Communauté"}>
            <TextField source="titre"/>
        </ReferenceField>
        <FieldGuesser source={"titre"}/>
        <FieldGuesser source={"couleur"}/>
    </ShowGuesser>
);
export const SondageCategorieEdit = props => (
    <EditGuesser {...props}>
        <ReferenceInput source="communaute_id" reference="communautes" name={"Communaute"}>
            <AutocompleteInput
                optionText={(choice) =>
                    choice?.id // the empty choice is { id: '' }
                        ? `${choice.titre}`
                        : ''
                }
                name={"communaute"} disabled />
        </ReferenceInput>
        <TextInput source={"titre"} name={"titre"}/>
        <TextInput source={"couleur"} name={'couleur'}/>
    </EditGuesser>
);