import {CreateGuesser, EditGuesser, FieldGuesser, InputGuesser, ShowGuesser} from "@api-platform/admin";
import {
    AutocompleteInput,
    BooleanField,
    Count,
    DatagridConfigurable,
    DateField,
    DateInput,
    ExportButton,
    FilterButton,
    List,
    NullableBooleanInput,
    NumberField,
    ReferenceField,
    ReferenceInput,
    SearchInput,
    SelectColumnsButton,
    TextField,
    TextInput,
    SelectInput,
    NumberInput,
    TopToolbar,
    useListContext,
    ShowButton,
    ListButton,
    useRecordContext,
    useUpdate,
    useDataProvider, Datagrid, CreateButton, ImageField, ReferenceManyField, ImageInput, minValue, maxValue, DateTimeInput
} from 'react-admin';
import { ColorField, ColorInput } from 'react-admin-color-picker';
import {useController, useWatch} from "react-hook-form";
import moment from "moment";

const filters = [
    <SearchInput source="q" alwaysOn />,
    <ReferenceInput source="communaute_id" reference="communautes" name={"Communaute"}>
        <AutocompleteInput
            optionText={(choice) =>
                choice?.id // the empty choice is { id: '' }
                    ? `${choice.titre}`
                    : ''
            }
            name={"communaute"}/>
    </ReferenceInput>,
    <TextInput source={"objet"} />,
];

export const BoiteAIdeeList = props => (
    <List {...props} filters={filters}>
        <Datagrid rowClick={"show"}>
            <FieldGuesser source={"communaute"} />
            <FieldGuesser source={"utilisateur"} />
            <FieldGuesser source={"objet"} />
            <FieldGuesser source={"contenu"} />
        </Datagrid>
    </List>
);



export const BoiteAIdeeShow = props => (
    <ShowGuesser {...props}>
        <FieldGuesser source={"communaute"} />
        <FieldGuesser source={"utilisateur"} />
        <FieldGuesser source={"objet"} />
        <FieldGuesser source={"contenu"} />
    </ShowGuesser>
);

export const BoiteAIdeeCreate = props => (
    <CreateGuesser {...props}>
        <ReferenceInput source="communaute_id" reference="communautes" name={"Communaute"}>
            <AutocompleteInput
                optionText={(choice) =>
                    choice?.id // the empty choice is { id: '' }
                        ? `${choice.titre}`
                        : ''
                }
                name={"communaute"}/>
        </ReferenceInput>
        <InputGuesser source={"utilisateur"} />
        <InputGuesser source={"objet"} />
        <InputGuesser source={"contenu"} />
    </CreateGuesser>
);

export const BoiteAIdeeEdit = props => (
    <EditGuesser {...props}>
        <ReferenceInput source="communaute_id" reference="communautes" name={"Communaute"}>
            <AutocompleteInput
                optionText={(choice) =>
                    choice?.id // the empty choice is { id: '' }
                        ? `${choice.titre}`
                        : ''
                }
                name={"communaute"} disabled />
        </ReferenceInput>
        <InputGuesser source={"utilisateur"} />
        <InputGuesser source={"objet"} />
        <InputGuesser source={"contenu"} />
    </EditGuesser>
);