import {CreateGuesser, EditGuesser, FieldGuesser, InputGuesser, ListGuesser, ShowGuesser} from "@api-platform/admin";
import {
    AutocompleteInput,
    BooleanField,
    Count,
    DatagridConfigurable,
    DateField,
    DateInput,
    ExportButton,
    FilterButton,
    List,
    NullableBooleanInput,
    NumberField,
    ReferenceField,
    ReferenceInput,
    SearchInput,
    SelectColumnsButton,
    TextField,
    TextInput,
    SelectInput,
    TopToolbar,
    useListContext,
    ShowButton,
    ListButton,
    useRecordContext,
    useUpdate,
    useDataProvider,
    Datagrid,
    CreateButton,
    ImageField,
    ReferenceManyField,
    ImageInput,
    FormDataConsumer,
    useRefresh,
    Button,
} from 'react-admin';
import {useParams} from "react-router-dom";
import React from "react";
import {useMutation} from "react-query";

const CseInfoListActions = () => (
    <TopToolbar>
        <SelectColumnsButton />
        <FilterButton />
        <CreateButton />
        <ExportButton />
    </TopToolbar>
);

const cseInfoFilters = [
    <SearchInput source="q" alwaysOn />,
    <ReferenceInput source="communaute_id" reference="communautes" name={"Communaute"}>
        <AutocompleteInput
            optionText={(choice) =>
                choice?.id // the empty choice is { id: '' }
                    ? `${choice.titre}`
                    : ''
            }
            name={"communaute"}/>
    </ReferenceInput>,
    <ReferenceInput source="auteur" reference="utilisateurs" name={"Auteur"}>
        <AutocompleteInput
            optionText={(choice) =>
                choice?.id // the empty choice is { id: '' }
                    ? `${choice.name}`
                    : ''
            }
            name={"auteur"}/>
    </ReferenceInput>,
    <TextInput source={"titre"} />,
    <TextInput source={"contenu"} />,
];

const rowClick = (id, resource, record) => {
    return 'show';
};

export const CseInfoList = props => {
    const { communauteId } = useParams();
    return (
        <List {...props}
              resource="cse_infos"
              actions={<CseInfoListActions />}
              filter={communauteId !== undefined ? { communaute: communauteId } : undefined}
              filters={cseInfoFilters}>
            <DatagridConfigurable rowClick={rowClick}>
                <ReferenceField reference={"communautes"} source={"communaute"} label={"Communauté"}>
                    <TextField source="titre"/>
                </ReferenceField>
                <FieldGuesser source={"titre"} />
                <FieldGuesser source={"contenu"} />
                <FieldGuesser source={"auteur"} />
                {/*<FieldGuesser source={"createdAt"} label={"Date de création"} />*/}
            </DatagridConfigurable>
        </List>
    );
}

export const CseInfoShow = props => (
    <ShowGuesser {...props}>
        <FieldGuesser source={"communaute"} />
        <FieldGuesser source={"auteur"} />
        <FieldGuesser source={"titre"} />
        <FieldGuesser source={"contenu"} />
        <ReferenceManyField
            reference="cse_info_media"
            target="cse_info"
            // sort={{ field: 'created_at', order: 'DESC' }}
        >
            <Datagrid>
                <ImageField source="src" />
                {/*<EditButton />*/}
            </Datagrid>
        </ReferenceManyField>
    </ShowGuesser>
);

export const CseInfoEdit = props => (
    <EditGuesser {...props}>
        <ReferenceInput source="communaute_id" reference="communautes" name={"Communaute"}>
            <AutocompleteInput
                optionText={(choice) =>
                    choice?.id // the empty choice is { id: '' }
                        ? `${choice.titre}`
                        : ''
                }
                name={"communaute"}/>
        </ReferenceInput>
        <TextInput source={"titre"} fullWidth={true} />
        <TextInput source={"contenu"} multiline={true} rows={10} fullWidth={true} />
        <ImageInput multiple={true}
                    source="medias"
                    label="Related pictures"
                    accept="image/*">
            <ImageField source="src" />
        </ImageInput>
    </EditGuesser>
);

export const CseInfoCreate = props => (
    <CreateGuesser {...props}>
        <ReferenceInput source="communaute_id" reference="communautes" name={"Communaute"}>
            <AutocompleteInput
                optionText={(choice) =>
                    choice?.id // the empty choice is { id: '' }
                        ? `${choice.titre}`
                        : ''
                }
                name={"communaute"}/>
        </ReferenceInput>
        <TextInput source={"titre"} fullWidth={true} />
        <TextInput source={"contenu"} multiline={true} rows={10} fullWidth={true} />
        <ImageInput multiple={true}
                    source="medias"
                    label="Related pictures"
                    accept="image/*">
            {/*<ImageField source="mediaUrl" title="filename" />*/}
            <ImageField source="src" />
        </ImageInput>
    </CreateGuesser>
);