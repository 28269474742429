import {CreateGuesser, EditGuesser, FieldGuesser, InputGuesser, ListGuesser, ShowGuesser} from "@api-platform/admin";
import {
    Edit,
    BooleanInput,
    AutocompleteInput,
    BooleanField,
    Count,
    SimpleForm,
    DatagridConfigurable,
    DateField,
    DateInput,
    ExportButton,
    FilterButton,
    List,
    Show,
    NullableBooleanInput,
    NumberField,
    ReferenceField,
    ReferenceInput,
    SearchInput,
    SelectColumnsButton,
    TextField,
    TextInput,
    SelectInput,
    TopToolbar,
    useListContext,
    ShowButton,
    ListButton,
    useRecordContext,
    useUpdate,
    useDataProvider,
    Datagrid,
    CreateButton,
    EditButton,
    Button,
    ReferenceManyField,
    ImageField,
    ImageInput, FormDataConsumer, Labeled,
    TabbedShowLayout, ReferenceArrayInput, useRefresh, TranslatableFields, TranslatableInputs
} from 'react-admin';
import { useMutation } from 'react-query';
import {Typography} from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import {useWatch} from "react-hook-form";
import {CibleInput, CommunauteInput, UtilisateurInput} from "../components/inputs";
import {useParams} from "react-router-dom";

export const PaysList = props => (
    <ListGuesser {...props}>
        <FieldGuesser source={"code"} />
        <FieldGuesser source={"alpha2"} />
        <FieldGuesser source={"alpha3"} />
        <FieldGuesser source={"actif"} />
        <TranslatableFields locales={['fr', 'en', 'es']}>
            <TextField source="nom" />
        </TranslatableFields>
    </ListGuesser>
);

export const PaysShow = props => (
    <ShowGuesser {...props}>
        <FieldGuesser source={"code"} />
        <FieldGuesser source={"alpha2"} />
        <FieldGuesser source={"alpha3"} />
        <FieldGuesser source={"actif"} />
        <TranslatableFields locales={['fr', 'en', 'es']}>
            <TextField source="nom" />
        </TranslatableFields>
    </ShowGuesser>
);

export const PaysCreate = props => (
    <CreateGuesser {...props}>
        <InputGuesser source={"code"} />
        <InputGuesser source={"alpha2"} />
        <InputGuesser source={"alpha3"} />
        <BooleanInput source={"actif"} />
        <TranslatableInputs locales={['fr', 'en', 'es']}>
            <TextInput source="nom" />
        </TranslatableInputs>
    </CreateGuesser>
);

export const PaysEdit = props => (
    <EditGuesser {...props}>
        <InputGuesser source={"code"} />
        <InputGuesser source={"alpha2"} />
        <InputGuesser source={"alpha3"} />
        <BooleanInput source={"actif"} />
        <TranslatableInputs locales={['fr', 'en', 'es']}>
            <TextInput source="nom" />
        </TranslatableInputs>
    </EditGuesser>
);