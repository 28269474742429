import {CreateGuesser, EditGuesser, FieldGuesser, InputGuesser, ListGuesser, ShowGuesser} from "@api-platform/admin";
import {List, Typography} from "@mui/material";
import {
    Datagrid,
    DateField,
    ReferenceManyField,
    ReferenceManyCount,
    TextField,
    BooleanField,
    ArrayField,
    SelectArrayInput,
    SingleFieldList,
    ChipField,
    SelectInput,
    FunctionField, ReferenceField
} from "react-admin";
import {
    Admin,
    Resource,
    useEditContext,
    useShowContext,
    useRecordContext,
    TabbedForm,
    Show,
    TabbedShowLayout
} from 'react-admin';
import {CommunauteInput} from "../components/inputs";
import React from "react";

export const DroitList = props => (
    <ListGuesser {...props}
                 rowClick={"edit"}
    >
        <ReferenceField reference={"communautes"} source={"communaute"} label={"Communauté"}>
            <TextField source="titre"/>
        </ReferenceField>
        <FieldGuesser source={"utilisateur"}/>
        <FieldGuesser source={"email"}/>
        <FieldGuesser source={"nom"}/>
        <FieldGuesser source={"prenom"}/>
        {/*<FieldGuesser source={"roles"} />*/}
        <ArrayField source="roles">
            <SingleFieldList>
                <FunctionField render={(record) => (
                    <ChipField record={{name: record}} source="name"/>
                )}/>
            </SingleFieldList>
        </ArrayField>
        <FieldGuesser source={"dateArrivee"}/>
        <FieldGuesser source={"actif"}/>
        <FieldGuesser source={"poste"}/>
        <FieldGuesser source={"biographie"}/>
        <FieldGuesser source={"dateDebut"}/>
        <FieldGuesser source={"dateFin"}/>
    </ListGuesser>
);

export const DroitShow = props => (
    <ShowGuesser {...props}>
        <ReferenceField reference={"communautes"} source={"communaute"} label={"Communauté"}>
            <TextField source="titre"/>
        </ReferenceField>
        <FieldGuesser source={"utilisateur"}/>
        <FieldGuesser source={"email"}/>
        <FieldGuesser source={"nom"}/>
        <FieldGuesser source={"prenom"}/>
        <FieldGuesser source={"roles"}/>
        <FieldGuesser source={"dateArrivee"}/>
        <FieldGuesser source={"actif"}/>
        <FieldGuesser source={"poste"}/>
        <FieldGuesser source={"biogrpahie"}/>
        <FieldGuesser source={"dateDebut"}/>
        <FieldGuesser source={"dateFin"}/>
    </ShowGuesser>
);

const DroitTypes = [
    {
        id: "UTILISATEUR",
        name: "Utilisateur"
    },
    {
        id: "MANAGER",
        name: "Manager"
    },
    {
        id: "UTILISATEUR_CSE",
        name: "CSE"
    },
    {
        id: "UTILISATEUR_EMPLOI",
        name: "Emploi"
    },
    {
        id: "UTILISATEUR_INDICATEUR",
        name: "Indicateurs"
    },
    {
        id: "UTILISATEUR_DOCUMENT",
        name: "Documents"
    },
    {
        id: "UTILISATEUR_PARCOURS_ACCUEIL",
        name: "Parcours d'accueil"
    },
    {
        id: "TRAITE_SIGNALEMENT",
        name: "Signalements"
    },
    {
        id: "TRAITE_DEMANDE",
        name: "Demandes"
    }
];

export const DroitEdit = props => (
    <EditGuesser {...props}>
        <CommunauteInput source="communaute" disabled/>
        <InputGuesser source={"utilisateur"} disabled/>
        <SelectArrayInput source={"roles"} choices={DroitTypes}/>
        <InputGuesser source={"dateArrivee"}/>
        <InputGuesser source={"poste"}/>
        <InputGuesser source={"biographie"}/>
        <InputGuesser source={"dateDebut"} />
        <InputGuesser source={"dateFin"} />
    </EditGuesser>
);

export const DroitCreate = props => (
    <CreateGuesser {...props}>
        <CommunauteInput source="communaute"/>
        <InputGuesser source={"email"}/>
        <SelectInput source="civilite" choices={[
            {id: 'M', name: 'Monsieur'},
            {id: 'Mme', name: 'Madame'},
        ]}/>
        <InputGuesser source={"nom"}/>
        <InputGuesser source={"prenom"}/>
        <SelectArrayInput source={"roles"} choices={DroitTypes}/>
        <InputGuesser source={"dateArrivee"}/>
        <InputGuesser source={"poste"}/>
        <InputGuesser source={"biographie"}/>
        <InputGuesser source={"dateDebut"} />
        <InputGuesser source={"dateFin"} />
    </CreateGuesser>
);