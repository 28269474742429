import {CreateGuesser, EditGuesser, FieldGuesser, InputGuesser, ListGuesser, ShowGuesser} from "@api-platform/admin";
import HorizontalRule from '@tiptap/extension-horizontal-rule';
import {
    Edit,
    BooleanInput,
    AutocompleteInput,
    BooleanField,
    Count,
    SimpleForm,
    DatagridConfigurable,
    DateField,
    DateInput,
    ExportButton,
    FilterButton,
    List,
    Show,
    NullableBooleanInput,
    NumberField,
    ReferenceField,
    ReferenceInput,
    SearchInput,
    SelectColumnsButton,
    TextField,
    TextInput,
    SelectInput,
    TopToolbar,
    useListContext,
    ShowButton,
    ListButton,
    useRecordContext,
    useUpdate,
    useDataProvider,
    Datagrid,
    CreateButton,
    EditButton,
    ReferenceManyField,
    ImageField,
    ImageInput, FormDataConsumer, Labeled,
    TabbedShowLayout
} from 'react-admin';
import { useMutation } from 'react-query';
import {
    DefaultEditorOptions,
    RichTextInput,
    RichTextInputToolbar,
    LevelSelect,
    FormatButtons,
    AlignmentButtons,
    ListButtons,
    LinkButtons,
    QuoteButtons,
    ClearButtons,
    useTiptapEditor,
} from 'ra-input-rich-text';
import {Button, Typography} from "@mui/material";
import SendIcon from '@mui/icons-material/Send';

export const SignalementMediaList = props => (
    <ListGuesser {...props} rowClick={"edit"}>
        <FieldGuesser source={"signalement"} />
        <ImageField source="src" label={"Image"} />
    </ListGuesser>
);

export const SignalementMediaShow = props => (
    <ShowGuesser {...props}>
        <FieldGuesser source={"signalement"} />
        <ImageField source="src" label={"Image"} />
    </ShowGuesser>
);

export const SignalementMediaCreate = props => (
    <CreateGuesser {...props}>
        <InputGuesser source={"signalement"} />
        <ImageInput source="file">
            <ImageField source="src" title="title" />
        </ImageInput>
        <InputGuesser source={"communaute"} />
    </CreateGuesser>
);