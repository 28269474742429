import {
    fetchHydra as baseFetchHydra,
    hydraDataProvider as baseHydraDataProvider
    // useIntrospection,
} from "@api-platform/admin";
import { parseHydraDocumentation } from "@api-platform/api-doc-parser";
import { ENTRYPOINT } from "../config";

const getHeaders = () => {
    if (localStorage.getItem("token")) {
        return {
            Authorization: `Bearer ${localStorage.getItem("token")}`
        };
    }
    return {};
};

export const fetchHydra = (url, options = {}) => {
    return baseFetchHydra(url, {
        ...options,
        headers: getHeaders
    });
};

// const RedirectToLogin = () => {
//     const introspect = useIntrospection();
//     if (localStorage.getItem("token")) {
//         introspect();
//         return <></>;
//     }
//     return <Navigate to="/login" replace />;
// }

const apiDocumentationParser = async () => {
    try {
        return await parseHydraDocumentation(ENTRYPOINT, { headers: getHeaders });
    } catch (result) {
        const { api, response, status } = result;
        if (status !== 401 || !response) {
            throw result;
        }

        // Prevent infinite loop if the token is expired
        localStorage.removeItem("token");

        return {
            api,
            response,
            status
            // customRoutes: [
            //     <Route key="/" path="/" element={<RedirectToLogin />} />
            // ],
        };
    }
};

export const dataProvider = baseHydraDataProvider({
    entrypoint: process.env.REACT_APP_API_URL,
    httpClient: fetchHydra,
    apiDocumentationParser
});
