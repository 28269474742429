import {CreateGuesser, EditGuesser, FieldGuesser, InputGuesser, ListGuesser, ShowGuesser} from "@api-platform/admin";
import {
    EditButton,
    ShowButton,
    AutocompleteInput,
    BooleanField,
    Count,
    DatagridConfigurable,
    DateField,
    DateInput,
    ExportButton,
    FilterButton,
    List,
    NullableBooleanInput,
    NumberField,
    ReferenceField,
    ReferenceInput,
    SearchInput,
    SelectColumnsButton,
    TextField,
    TextInput,
    BooleanInput,
    SelectInput,
    TopToolbar,
    useListContext,
    ListButton,
    useRecordContext,
    useUpdate,
    useDataProvider, Datagrid, CreateButton,
} from 'react-admin';
import React from "react";

const DeviceListActions = () => (
    <TopToolbar>
        <SelectColumnsButton />
        <FilterButton />
        <CreateButton />
        <ExportButton />
    </TopToolbar>
);

const deviceTypes = [
    { id: 'ios', name: 'iOS' },
    { id: 'android', name: 'Android' },
    { id: 'web', name: 'Web' },
    { id: 'macos', name: 'MacOS' },
    { id: 'windows', name: 'Windows' },
    { id: 'linux', name: 'Linux' },
];

const deviceFilters = [
    <ReferenceInput source="utilisateur_id" reference="utilisateurs" name={"Utilisateur"}>
        <AutocompleteInput
            optionText={(choice) =>
                choice?.id // the empty choice is { id: '' }
                    ? `${choice.name}`
                    : ''
            }
            name={"utilisateur"}/>
    </ReferenceInput>,
    <SelectInput source="type" name={"type"} field={"type"} choices={deviceTypes} />,
    <TextInput source={"appname"} />,
    <TextInput source={"appversion"} />,
    <TextInput source={"model"} />,
    <TextInput source={"version"} />,
    <BooleanInput source={"pushbadge"} />,
    <BooleanInput source={"pushalert"} />,
    <BooleanInput source={"pushsound"} />,
    <BooleanInput source={"status"} />,
];

export const DeviceList = props => (
    <List {...props} actions={<DeviceListActions />} filters={deviceFilters}>
        <DatagridConfigurable rowClick={"edit"}>
            <ReferenceField reference={"utilisateurs"} source={"utilisateur"} label={"Utilisateur"}>
                <TextField source="name"/>
            </ReferenceField>
            <FieldGuesser source={"type"} />
            <FieldGuesser source={"identifier"} />
            <FieldGuesser source={"appname"} />
            <FieldGuesser source={"appversion"} />
            <FieldGuesser source={"model"} />
            <FieldGuesser source={"version"} />
            <FieldGuesser source={"pushbadge"} />
            <FieldGuesser source={"pushalert"} />
            <FieldGuesser source={"pushsound"} />
            <FieldGuesser source={"status"} />
            <ShowButton />
            <EditButton />
        </DatagridConfigurable>
    </List>
);

export const DeviceShow = props => (
    <ShowGuesser {...props}>
            <FieldGuesser source={"utilisateur"} />
            <FieldGuesser source={"type"} />
            <FieldGuesser source={"identifier"} />
            <FieldGuesser source={"appname"} />
            <FieldGuesser source={"appversion"} />
            <FieldGuesser source={"model"} />
            <FieldGuesser source={"version"} />
            <FieldGuesser source={"pushbadge"} />
            <FieldGuesser source={"pushalert"} />
            <FieldGuesser source={"pushsound"} />
            <FieldGuesser source={"status"} />
    </ShowGuesser>
);

export const DeviceEdit = props => (
    <EditGuesser {...props}>
        <InputGuesser source={"utilisateur"} />
        <SelectInput source="type" name={"type"} field={"type"} choices={deviceTypes} />
        <InputGuesser source={"identifier"} />
        <InputGuesser source={"appname"} />
        <InputGuesser source={"appversion"} />
        <InputGuesser source={"model"} />
        <InputGuesser source={"version"} />
        <InputGuesser source={"pushbadge"} />
        <InputGuesser source={"pushalert"} />
        <InputGuesser source={"pushsound"} />
        <InputGuesser source={"status"} />
    </EditGuesser>
);

export const DeviceCreate = props => (
    <CreateGuesser {...props}>
        <InputGuesser source={"utilisateur"} />
        <SelectInput source="type" name={"type"} field={"type"} choices={deviceTypes} />
        <InputGuesser source={"identifier"} />
        <InputGuesser source={"appname"} />
        <InputGuesser source={"appversion"} />
        <InputGuesser source={"model"} />
        <InputGuesser source={"version"} />
        <InputGuesser source={"pushbadge"} />
        <InputGuesser source={"pushalert"} />
        <InputGuesser source={"pushsound"} />
        <InputGuesser source={"status"} />
    </CreateGuesser>
);

