import {CreateGuesser, FieldGuesser, InputGuesser, ListGuesser, ShowGuesser} from "@api-platform/admin";
import {ImageField, ImageInput} from "react-admin";

export const SondageMediaList = props => (
    <ListGuesser {...props} rowClick={"edit"}>
        <FieldGuesser source={"sondage"} />
        <ImageField source="src" label={"Image"} />
    </ListGuesser>
);

export const SondageMediaShow = props => (
    <ShowGuesser {...props}>
        <FieldGuesser source={"sondage"} />
        <ImageField source="src" label={"Image"} />
    </ShowGuesser>
);

export const SondageMediaCreate = props => (
    <CreateGuesser {...props}>
        <InputGuesser source={"sondage"} />
        <ImageInput source="file">
            <ImageField source="src" title="title" />
        </ImageInput>
        <InputGuesser source={"communaute"} />
    </CreateGuesser>
);