import {CreateGuesser, EditGuesser, FieldGuesser, InputGuesser, ListGuesser, ShowGuesser} from "@api-platform/admin";
import {Typography} from "@mui/material";
import {Link} from 'react-router-dom';
import {
    List,
    Datagrid,
    DateField,
    ReferenceManyField,
    ReferenceManyCount,
    TextField,
    BooleanField,
    ArrayField,
    SingleFieldList,
    FunctionField,
    ChipField,
    EditButton,
    ReferenceField,
    BulkDeleteButton,
    useResourceContext,
    TopToolbar,
    SelectColumnsButton,
    CreateButton,
    ExportButton,
    FilterButton,
    DatagridConfigurable,
    TextInput,
    ReferenceInput,
    AutocompleteInput,
    ShowButton,
    SearchInput,
    ImageInput,
    ImageField,
    useDataProvider,
    useRefresh,
    BooleanInput,
    FormDataConsumer, ArrayInput, SimpleFormIterator, AutocompleteArrayInput, ReferenceArrayInput, SelectArrayInput,
    required, Pagination
} from "react-admin";
import {
    Admin,
    Resource,
    useEditContext,
    useShowContext,
    useRecordContext,
    TabbedForm,
    Show,
    TabbedShowLayout
} from 'react-admin';
import ListeDroits from "./communaute_droits.tsx";
import {ColorField, ColorInput} from 'react-admin-color-picker';
import {Button} from '@mui/material';
import {useMutation} from "react-query";
import SendIcon from "@mui/icons-material/Send";
import {ENTRYPOINT} from "../config";
import React from "react";

const CommunauteListActions = () => (
    <TopToolbar>
        <SelectColumnsButton/>
        <FilterButton/>
        <CreateButton/>
        <ExportButton/>
    </TopToolbar>
);

const filters = [
    <SearchInput source="q" alwaysOn/>,
    <TextInput source={"titre"}/>,
    <TextInput source={"codePostal"}/>,
    <TextInput source={"ville"}/>,
    <TextInput source={"pays"}/>,
];

const rowClick = (id, resource, record) => {
    return 'show';
};

export const CommunauteList = props => (
    <List {...props} actions={<CommunauteListActions/>} filters={filters}>
        <DatagridConfigurable rowClick={rowClick}>
            <FieldGuesser source="titre"/>
            <FieldGuesser source="codePostal"/>
            <FieldGuesser source="ville"/>
            <FieldGuesser source="pays"/>
            {/*<ShowButton />*/}
            <EditButton/>
        </DatagridConfigurable>
    </List>
);

const MembreTab = () => {
    const {record} = useShowContext();
    return (
        <ListeDroits communaute={record}/>
    )
};

const CommunauteTitle = () => {
    const record = useRecordContext();
    // the record can be empty while loading
    if (!record) return null;
    return <span>Communaute "{record.titre}"</span>;
};

const MyCommunauteShow = props => {
    const record = useRecordContext();
    //
    // const { isLoading } = useShowContext();
    // if (!isLoading) return null;
    //
    if (!record) return null;
    return (
        <TabbedShowLayout>
            <TabbedShowLayout.Tab
                label="Résumé">
                <div>
                    <Button
                        component={Link}
                        to={`/communautes/${encodeURIComponent(record.id)}/publications`}
                        variant="contained"
                        sx={{m: 0.5}}
                    >
                        Publications
                    </Button>
                    {/*<Button*/}
                    {/*    component={Link}*/}
                    {/*    to={`/communautes/${encodeURIComponent(record.id)}/droits`}*/}
                    {/*    color="secondary"*/}
                    {/*    variant="contained"*/}
                    {/*    sx={{ m: 0.5 }}*/}
                    {/*>*/}
                    {/*    Membres*/}
                    {/*</Button>*/}
                    {/*<Button*/}
                    {/*    component={Link}*/}
                    {/*    to={`/communautes/${encodeURIComponent(record.id)}/equipes`}*/}
                    {/*    color="secondary"*/}
                    {/*    variant="contained"*/}
                    {/*    sx={{ m: 0.5 }}*/}
                    {/*>*/}
                    {/*    Equipes*/}
                    {/*</Button>*/}
                    <Button
                        component={Link}
                        to={`/communautes/${encodeURIComponent(record.id)}/evenements`}
                        variant="contained"
                        sx={{m: 0.5}}
                    >
                        Evenements
                    </Button>
                    <Button
                        component={Link}
                        to={`/communautes/${encodeURIComponent(record.id)}/offre_emplois`}
                        variant="contained"
                        sx={{m: 0.5}}
                    >
                        Offre emplois
                    </Button>
                    <Button
                        component={Link}
                        to={`/communautes/${encodeURIComponent(record.id)}/cse_infos`}
                        variant="contained"
                        sx={{m: 0.5}}
                    >
                        Infos CSE
                    </Button>
                    <Button
                        component={Link}
                        to={`/communautes/${encodeURIComponent(record.id)}/signalements`}
                        variant="contained"
                        sx={{m: 0.5}}
                    >
                        Signalements
                    </Button>
                    <Button
                        component={Link}
                        to={`/communautes/${encodeURIComponent(record.id)}/sondages`}
                        variant="contained"
                        sx={{m: 0.5}}
                    >
                        Sondages
                    </Button>
                </div>
                <FieldGuesser source={"titre"}/>
                <FieldGuesser source={"adresse"}/>
                <FieldGuesser source={"codePostal"}/>
                <FieldGuesser source={"ville"}/>
                <FieldGuesser source={"pays"}/>
                <ColorField source={"couleurPrimaire"}/>
                <ColorField source={"couleurSecondaire"}/>
                <FieldGuesser source={"description"}/>
                <FieldGuesser source={"siteWeb"}/>
                <FieldGuesser source={"reseauxSociaux"}/>
                <FieldGuesser source={"organigrammePublic"}/>
                <FieldGuesser source={"accepteDemandeExterne"}/>
                <FieldGuesser source={"doubleAuthentification"}/>
                <FieldGuesser source={"accepteLike"}/>
                <FieldGuesser source={"accepteCommentaire"}/>
                <FieldGuesser source={"visibiliteAuteurResource"}/>
                <FieldGuesser source={"anniversaireIncontournableActif"}/>
                <FieldGuesser label={"Options activées"} source={"options"}/>
                <FieldGuesser label={"Options désactivées"} source={"optionsDesactivees"}/>
                <ImageField sx={{mb: 3}} source={"logo"}/>
                <ImageField label={"Image de couverture"} source={"couverture"}/>

            </TabbedShowLayout.Tab>
            <TabbedShowLayout.Tab
                label="Contrats"
                path="contrats">

            </TabbedShowLayout.Tab>
            <TabbedShowLayout.Tab
                label="Demande adhésion"
                path="demandes_adhesion"
                count={
                    <ReferenceManyCount
                        reference="demande_adhesions"
                        target="communaute"
                        sx={{lineHeight: 'inherit'}}
                    />
                }>
                <ReferenceManyField
                    label={"Demande adhésions"}
                    reference={"demande_adhesions"}
                    target={"communaute"}
                    sort={{field: 'createdAt', order: 'DESC'}}
                    pagination={<Pagination />}
                >
                    <Datagrid>
                        <FieldGuesser source={"email"}/>
                        <FieldGuesser source={"telephone"}/>
                        <FieldGuesser source={"nom"}/>
                        <FieldGuesser source={"prenom"}/>
                        <FieldGuesser source={"dateNaissance"}/>
                        <EditButton/>
                    </Datagrid>
                </ReferenceManyField>
            </TabbedShowLayout.Tab>
            <TabbedShowLayout.Tab
                label="Membres"
                path="membres"
                count={
                    <ReferenceManyCount
                        reference="droits"
                        target="communaute"
                        sx={{lineHeight: 'inherit'}}
                    />
                }>

                if(record.id != undefined){
                    <Button
                        variant="raised"
                        component={Link}
                        to={{
                            pathname: `/droits/create`,
                            search: `?source=${JSON.stringify({communaute: record.id})}`,
                        }} //
                        label="Ajouter un membre"
                        title="Ajouter un membre"
                    >Ajouter un membre</Button>
                }

                {/*<MembreTab />*/}
                <ReferenceManyField
                    label={"Membres"}
                    reference={"droits"}
                    target={"communaute"}
                    sort={{field: 'createdAt', order: 'DESC'}}
                    pagination={<Pagination />}
                >
                    <Datagrid>
                        <TextField source={"email"}/>
                        <TextField source={"prenom"}/>
                        <TextField source={"nom"}/>
                        <DateField source={"dateArrivee"}/>
                        <BooleanField source={"actif"}/>
                        <ArrayField source="roles">
                            <SingleFieldList>
                                <FunctionField render={(record) => (
                                    <ChipField record={{name: record}} source="name"/>
                                )}/>
                            </SingleFieldList>
                        </ArrayField>
                        <EditButton/>
                    </Datagrid>
                </ReferenceManyField>
            </TabbedShowLayout.Tab>
            <TabbedShowLayout.Tab
                label="Equipes"
                path="equipes"
                count={
                    <ReferenceManyCount
                        reference="equipes"
                        target="communaute"
                        sx={{lineHeight: 'inherit'}}
                    />
                }>
                if(record.id != undefined){
                <Button
                    variant="raised"
                    component={Link}
                    to={{
                        pathname: `/equipes/create`,
                        search: `?source=${JSON.stringify({communaute: record.id})}`,
                    }} //
                    label="Ajouter une équipe"
                    title="Ajouter une équipe"
                >Ajouter une équipe</Button>
            }
                <ReferenceManyField
                    reference={"equipes"}
                    target={"communaute"}
                    pagination={<Pagination />}
                >
                    <Datagrid rowClick={"show"}>
                        <TextField source={"nom"}/>
                        <ReferenceField reference={"equipe"} source={"equipeParent"}>
                            <TextField source="nom"/>
                        </ReferenceField>
                        <BooleanField source={"cloisonnante"}/>
                        <BooleanField source={"structurante"}/>
                        <EditButton/>
                    </Datagrid>
                </ReferenceManyField>

            </TabbedShowLayout.Tab>
            <TabbedShowLayout.Tab
                label="Publications"
                path="publications"
                count={
                    <ReferenceManyCount
                        reference="publications"
                        target="communaute"
                        sx={{lineHeight: 'inherit'}}
                    />
                }>
                if(record.id != undefined){
                <Button
                    variant="raised"
                    component={Link}
                    to={{
                        pathname: `/publications/create`,
                        search: `?source=${JSON.stringify({communaute: record.id})}`,
                    }} //
                    label="Ajouter une publication"
                    title="Ajouter une publication"
                >Ajouter une publication</Button>
            }
                <ReferenceManyField
                    reference={"publications"}
                    target={"communaute"}
                    sort={{field: 'createdAt', order: 'DESC'}}
                    pagination={<Pagination />}
                >
                    <Datagrid>
                        <TextField source={"titre"}/>
                        <TextField source={"etat"}/>
                        <TextField source={"type"}/>
                        <ReferenceField reference={"utilisateurs"} source={"utilisateurResource"} label={"Utilisateur"}>
                            <TextField source="name"/>
                        </ReferenceField>
                        <DateField source={"createdAt"}/>
                        <EditButton/>
                    </Datagrid>
                </ReferenceManyField>
            </TabbedShowLayout.Tab>
        </TabbedShowLayout>
    );
}

export const CommunauteShow = props => {
    return (
        <Show {...props}>
            <MyCommunauteShow/>
        </Show>
    );
}


const SupprimerLogoBouton = () => {
    const record = useRecordContext();
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const {mutate, isLoading} = useMutation(
        () => dataProvider.update(
            `communautes`,
            {id: record.id, data: {deleteLogo: true}, previousData: record}
        ).then(
            () => refresh()
        )
    );


    return <Button
        color="primary"
        size={"small"}
        sx={{mb: 3}}
        onClick={() => mutate()}
        disabled={isLoading}> Supprimer le logo</Button>;
};

const SupprimerCouvertureBouton = () => {
    const record = useRecordContext();
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const {mutate, isLoading} = useMutation(
        () => dataProvider.update(
            `communautes`,
            {id: record.id, data: {deleteCouverture: true}, previousData: record}
        ).then(
            () => refresh()
        )
    );


    return <Button
        color="primary"
        size={"small"}
        sx={{mb: 3}}
        onClick={() => mutate()}
        disabled={isLoading}> Supprimer l'image de couverture</Button>;
};

export const CommunauteEdit = props => (
    <EditGuesser {...props}>
        <InputGuesser source={"titre"}/>
        <InputGuesser source={"adresse"}/>
        <InputGuesser source={"codePostal"}/>
        <InputGuesser source={"ville"}/>
        <InputGuesser source={"pays"}/>
        <ColorInput source={"couleurPrimaire"} picker="Sketch"/>
        <ColorInput source={"couleurSecondaire"} picker="Sketch"/>
        <InputGuesser source={"description"}/>
        <InputGuesser source={"siteWeb"}/>
        <ArrayInput source={"reseauxSociaux"}>
            <SimpleFormIterator inline disableReordering>
                <AutocompleteInput source="nom" choices={[
                    {id: 'facebook', name: 'Facebook'},
                    {id: 'instagram', name: 'Instagram'},
                    {id: 'linkedin', name: 'Linkedin'},
                ]}/>
                <TextInput source="url"/>
            </SimpleFormIterator>
        </ArrayInput>
        <InputGuesser source={"organigrammePublic"}/>
        <InputGuesser source={"accepteDemandeExterne"}/>
        <InputGuesser source={"doubleAuthentification"}/>
        <InputGuesser source={"accepteLike"}/>
        <InputGuesser source={"accepteCommentaire"}/>
        <InputGuesser source={"visibiliteAuteurResource"}/>
        <BooleanInput  source={"anniversaireIncontournableActif"} defaultValue="true"/>
        <AutocompleteArrayInput source="optionsDesactivees" choices={OptionsTypes}  name={"optionsDesactivees"}/>
        <ImageField source={"logo"}/>
        <FormDataConsumer>
            {({
                  formData,
                  scopedFormData,
                  getSource,
                  ...rest
              }) => (formData.logo !== null) ? <SupprimerLogoBouton/> : null}

        </FormDataConsumer>
        <ImageInput multiple={false}
                    name={"file"}
                    label="Changer de logo"
                    accept="image/*">
            <ImageField source="src"/>
        </ImageInput>
        <ImageField source={"couverture"}/>
        <FormDataConsumer>
            {({
                  formData,
                  scopedFormData,
                  getSource,
                  ...rest
              }) => (formData.couverture !== null) ? <SupprimerCouvertureBouton/> : null}

        </FormDataConsumer>
        <ImageInput multiple={false}
                    name={"couvertureFile"}
                    label="Changer d'image de couverture"
                    accept="image/*"
        >
            <ImageField source="src"/>
        </ImageInput>
    </EditGuesser>
);

const OptionsTypes = [
    {
        id: "PUBLICATION",
        name: "Publication"
    },
    {
        id: "EVENEMENT",
        name: "Evenement"
    },
    {
        id: "INCONTOURNABLE",
        name: "Incontournable"
    },
    {
        id: "BOITE_A_IDEE",
        name: "Boite à idée"
    },
    {
        id: "SONDAGE",
        name: "Sondages"
    },
    {
        id: "EMPLOI",
        name: "Emploi"
    },
    {
        id: "INDICATEUR",
        name: "Indicateur"
    },
    {
        id: "SIGNALEMENT",
        name: "Signalements"
    },
    {
        id: "DOCUMENT",
        name: "Document"
    },
    {
        id: "CSE",
        name: "Cse"
    },
    {
        id: "DISCUSSION",
        name: "Discussion"
    }
];

export const CommunauteCreate = props => (
    <CreateGuesser {...props}>
        <InputGuesser source={"titre"} validate={[required()]}/>
        <InputGuesser source={"adresse"} validate={[required()]}/>
        <InputGuesser source={"codePostal"} validate={[required()]}/>
        <InputGuesser source={"ville"} validate={[required()]}/>
        <InputGuesser source={"pays"}/>
        <ColorInput source={"couleurPrimaire"} picker="Sketch"/>
        <ColorInput source={"couleurSecondaire"} picker="Sketch"/>
        <InputGuesser source={"description"}/>
        <InputGuesser source={"siteWeb"}/>
        <ArrayInput source={"reseauxSociaux"}>
            <SimpleFormIterator inline disableReordering>
                <AutocompleteInput source="nom" choices={[
                    {id: 'facebook', name: 'Facebook'},
                    {id: 'instagram', name: 'Instagram'},
                    {id: 'linkedin', name: 'Linkedin'},
                ]}/>
                <TextInput source="url"/>
            </SimpleFormIterator>
        </ArrayInput>
        <InputGuesser source={"organigrammePublic"}/>
        <InputGuesser source={"accepteDemandeExterne"}/>
        <InputGuesser source={"doubleAuthentification"}/>
        <InputGuesser source={"accepteLike"}/>
        <InputGuesser source={"accepteCommentaire"}/>
        <InputGuesser source={"visibiliteAuteurResource"}/>
        <BooleanInput  source={"anniversaireIncontournableActif"} defaultValue="true"/>
        <AutocompleteArrayInput source="optionsDesactivees" choices={OptionsTypes}  name={"optionsDesactivees"}/>
        <ImageInput multiple={false}
                    name={"file"}
                    label="Ajouter un logo"
                    accept="image/*">
            <ImageField source="src"/>
        </ImageInput>
        <ImageInput multiple={false}
                    name={"couvertureFile"}
                    label="Ajouter une image de couverture"
                    accept="image/*">
            <ImageField source="src"/>
        </ImageInput>
    </CreateGuesser>
);