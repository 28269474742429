import {
    ArrayField, ArrayInput, AutocompleteInput,
    BooleanField, BooleanInput,
    Button,
    ChipField, Create,
    CreateButton,
    Datagrid,
    DatagridConfigurable, DateInput, DateTimeInput,
    Edit,
    EditButton,
    ExportButton,
    FilterButton,
    FormDataConsumer,
    FunctionField,
    ImageField,
    ImageInput,
    List,
    ListButton,
    NumberField, NumberInput, ReferenceField,
    ReferenceInput,
    ReferenceManyField,
    ReferenceOneField,
    SearchInput,
    SelectColumnsButton,
    SelectInput,
    ShowButton,
    SimpleForm, SimpleFormIterator,
    SingleFieldList, TabbedForm, TabbedFormTabs,
    TabbedShowLayout,
    TextField,
    TextInput, TimeInput,
    TopToolbar,
    useDataProvider,
    useRecordContext,
    useRefresh
} from "react-admin";
import {CreateGuesser, FieldGuesser, ShowGuesser} from "@api-platform/admin";
import React, {Component} from "react";
import {useWatch} from "react-hook-form";
import {CibleInput, CommunauteInput} from "../components/inputs";
import {useMutation} from "react-query";
import SendIcon from "@mui/icons-material/Send";
import {useParams} from "react-router-dom";


const filters = [
    <SearchInput source="q" alwaysOn name={'Recherche'}/>,
    <CommunauteInput source="communaute"/>,
    <SelectInput source="etat" name={"etat"} field={"etat"} choices={[
        {id: 'brouillon', name: 'Brouillon'},
        {id: 'planifie', name: 'Planifié'},
        {id: 'attente_publication', name: 'Attente publication'},
        {id: 'attente_validation', name: 'Attente validation'},
        {id: 'publie', name: 'Publié'},
        {id: 'resultat', name: 'Résultat'},
        {id: 'archive', name: 'Archivé'},
    ]}/>
];

const SondageListActions = () => (
    <TopToolbar>
        <SelectColumnsButton/>
        <FilterButton/>
        <CreateButton/>
        <ExportButton/>
    </TopToolbar>
);


export const SondageList = props => {
    const {communauteId} = useParams();
    return (
        <List
            filters={filters}
            filter={{communaute: communauteId}}
            actions={<SondageListActions/>}
            sort={{field: 'createdAt', order: 'DESC'}}
            {...props}>
            <DatagridConfigurable rowClick={"show"}>
                <ReferenceField reference={"communautes"} source={"communaute"} label={"Communauté"}>
                    <TextField source="titre"/>
                </ReferenceField>
                <ReferenceField reference={"utilisateurs"} source={"utilisateur"} label={"Utilisateur"}>
                    <TextField source="name"/>
                </ReferenceField>
                <FieldGuesser source={"sondageCategorie"} label={"Catégorie"}/>
                <FieldGuesser source={"titre"}/>
                <FieldGuesser source={"dateDebut"}/>
                <FieldGuesser source={"dateFin"}/>
                <FieldGuesser source={"publicationResultat"}/>
                <FieldGuesser source={"etat"}/>,
                <FieldGuesser source={"createdAt"}/>
                <EditButton/>
            </DatagridConfigurable>
        </List>
    );
}

const CategorieInput = props => {
    const communaute = useWatch({name: 'communaute'});
    return (
        <FormDataConsumer>
            {({formData, ...rest}) => (communaute !== undefined && communaute !== null) &&
                <ReferenceInput reference="sondage_categories" filter={{communaute: communaute}} {...props} />
            }
        </FormDataConsumer>
    )
};

const MultiTabSondageShow = props => {
    return (
        <TabbedShowLayout>
            <TabbedShowLayout.Tab
                label="Informations sondage">
                <ReferenceField reference={"communautes"} source={"communaute"} label={"Communauté"}>
                    <TextField source="titre"/>
                </ReferenceField>
                <ReferenceField reference={"utilisateurs"} source={"utilisateur"} label={"Utilisateur"}>
                    <TextField source="name"/>
                </ReferenceField>
                <FieldGuesser sx={{mb: 2}} source={"cible"}/>
                <FieldGuesser sx={{mb: 2}} source={"sondageCategorie"}/>
                <FieldGuesser sx={{mb: 2}} source={"titre"}/>
                <FieldGuesser sx={{mb: 2}} source={"slug"}/>
                <FieldGuesser sx={{mb: 2}} source={"description"}/>
                <FieldGuesser sx={{mb: 2}} source={"dateDebut"}/>
                <FieldGuesser sx={{mb: 2}} source={"dateFin"}/>
                <FieldGuesser sx={{mb: 2}} source={"etat"}/>
                <FieldGuesser sx={{mb: 2}} source={"publicationResultat"}/>
                <FieldGuesser sx={{mb: 2}} source={"createdAt"} label={"Date création"}/>
                <ReferenceOneField label="Media" reference="sondage_media" target="sondage_id">
                    <ImageField source="src" title="Image"/>
                </ReferenceOneField>
            </TabbedShowLayout.Tab>
            <TabbedShowLayout.Tab label={'Questions'}>
                <ArrayField source={'sondageQuestions'} label={""}>
                    <Datagrid bulkActionButtons={false} sx={{mt: 3}}>
                        <TextField source="question" label={"Titre de la question"}/>
                        <TextField source={"type"}/>
                        <NumberField source={"ordre"}/>
                        <BooleanField  source={"required"} label={"Obligatoire"}/>
                        <ArrayField source="questionChoix" label={"Question choix réponses"}>
                            <SingleFieldList>
                                <ChipField source="libelle" label="Choix disponible"/>
                            </SingleFieldList>
                        </ArrayField>
                    </Datagrid>
                </ArrayField>
            </TabbedShowLayout.Tab>
        </TabbedShowLayout>
    );
}

export const SondageShow = props => (
    <ShowGuesser {...props}>
        <MultiTabSondageShow/>
    </ShowGuesser>
);


const SondageEditActions = () => {
    const record = useRecordContext();
    return (
        <TopToolbar>
            <ShowButton/>
            <ListButton/>
            {record !== undefined && record.transitions.includes('attente_publication') && (<PublierButton/>)}
            {record !== undefined && record.transitions.includes('archive') && (<ArchiverButton/>)}
            {record !== undefined && record.datePlanification !== undefined && record.transitions.includes('planifier') && (
                <PlanifierButton/>)}
            {record !== undefined && record.transitions.includes('valider') && (<ValiderButton/>)}
        </TopToolbar>
    );
}
const PublierButton = () => {
    const record = useRecordContext();
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const {mutate, isLoading} = useMutation(
        () => dataProvider.update(
            `publications`,
            {id: record.id, data: {etat: 'pretPublier'}}
        ).then(
            () => refresh()
        )
    );
    return <Button color="primary" size={"small"} hildren={SendIcon} label="Publier" onClick={() => mutate()}
                   disabled={isLoading}/>;
};

const ValiderButton = () => {
    const record = useRecordContext();
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const {mutate, isLoading} = useMutation(
        () => dataProvider.update(
            `publications`,
            {id: record.id, data: {etat: 'valider'}}
        ).then(
            () => refresh()
        )
    );
    return <Button
        color="primary"
        size={"small"}
        hildren={SendIcon}
        label="Valider"
        onClick={() => mutate()}
        disabled={isLoading}/>
        ;
};

const PlanifierButton = () => {
    const record = useRecordContext();
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const {mutate, isLoading} = useMutation(
        () => dataProvider.update(
            `publications`,
            {id: record.id, data: {etat: 'planifier'}}
        ).then(
            () => refresh()
        )
    );
    return <Button
        color="primary"
        size={"small"}
        hildren={SendIcon}
        label="Planifier"
        onClick={() => mutate()}
        disabled={isLoading}/>;
};

const ArchiverButton = () => {
    const record = useRecordContext();
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const {mutate, isLoading} = useMutation(
        () => dataProvider.update(
            `publications`,
            {id: record.id, data: {etat: 'archiver'}}
        ).then(
            () => refresh()
        )
    );
    return <Button
        color="primary"
        size={"small"}
        hildren={SendIcon}
        label="Archiver"
        onClick={() => mutate()}
        disabled={isLoading}/>;
};


export const SondageEdit = props => (
    <Edit {...props} actions={<SondageEditActions/>}>
        <TabbedForm>
            <TabbedForm.Tab label={"Sondage"}>
                <CommunauteInput source="communaute"/>
                <CibleInput source="cible"/>
                <CategorieInput source="sondageCategorie"/>
                <TextInput name={"Titre"} source={"titre"} fullWidth={true}/>
                <TextInput name={"Description"} source={"description"} multiline={true} rows={10} fullWidth={true}/>
                <DateInput source={"dateDebut"}/>
                <DateInput source={"dateFin"}/>
                <BooleanInput source={"publicationResultat"} label={"Publication des résultats"}/>
                <ImageInput multiple={false}
                            source="media"
                            label="Image illustration sondage"
                            accept="image/*">
                    <ImageField source="src"/>
                </ImageInput>
            </TabbedForm.Tab>
            <TabbedForm.Tab label={"Questions"}>
                <ArrayInput source={'sondageQuestions'} label={""}>
                    <SimpleFormIterator inline disableReordering>
                        <TextInput source={"question"}/>
                        <NumberInput source={"ordre"}/>
                        <AutocompleteInput source={"type"} choices={[
                            {id: 'reponse_courte', name: 'Réponse courte'},
                            {id: 'reponse_longue', name: 'Réponse longue'},
                            {id: 'choix_simple', name: 'Choix simple'},
                            {id: 'choix_multiple', name: 'Choix multiple'},
                            {id: 'reponse_date', name: 'Réponse date'},
                            {id: 'reponse_heure', name: 'Réponse heure'},
                        ]}/>
                        <BooleanInput defaultValue={false}  source={"required"} label="Obligatoire" />
                        <FormDataConsumer>
                            {({
                                  formData,
                                  scopedFormData,
                                  getSource,
                                  ...rest
                              }) => (scopedFormData.type === 'choix_simple' || scopedFormData.type === 'choix_multiple') ?
                                <ArrayInput record={scopedFormData} source={getSource('questionChoix')}
                                            label={"Question choix réponses"} {...rest}>
                                    <SimpleFormIterator inline disableReordering
                                                        getItemLabel={index => `#${index + 1}`}>
                                        <TextInput record={scopedFormData} source='libelle'/>
                                        <NumberInput record={scopedFormData} source="ordre"/>
                                    </SimpleFormIterator>
                                </ArrayInput>
                                : null
                            }
                        </FormDataConsumer>
                    </SimpleFormIterator>
                </ArrayInput>
            </TabbedForm.Tab>
        </TabbedForm>
    </Edit>
);


export const SondageCreate = props => (
    <Create {...props}>
        <TabbedForm>
            <TabbedForm.Tab label={"Sondage"}>
                <CommunauteInput source="communaute"/>
                <CibleInput source="cible"/>
                <CategorieInput source="sondageCategorie"/>
                <TextInput name={"titre"} source={"titre"} fullWidth={true}/>
                <TextInput name={"Description"} source={"description"} multiline={true} rows={10} fullWidth={true}/>
                <DateInput source={"dateDebut"}/>
                <DateInput source={"dateFin"}/>
                <BooleanInput defaultValue={false} source={"publicationResultat"} label={"Publication des résultats"}/>
                <ImageInput multiple={false}
                            source="media"
                            label="Image illustration sondage"
                            accept="image/*">
                    <ImageField source="src"/>
                </ImageInput>
            </TabbedForm.Tab>
            <TabbedForm.Tab label={"Questions"}>
                <ArrayInput source={'sondageQuestions'} label={""}>
                    <SimpleFormIterator inline disableReordering>
                        <TextInput source="question"/>
                        <NumberInput source={"ordre"}/>
                        <AutocompleteInput source="type" choices={[
                            {id: 'reponse_courte', name: 'Réponse courte'},
                            {id: 'reponse_longue', name: 'Réponse longue'},
                            {id: 'choix_simple', name: 'Choix simple'},
                            {id: 'choix_multiple', name: 'Choix multiple'},
                            {id: 'reponse_date', name: 'Réponse date'},
                            {id: 'reponse_heure', name: 'Réponse heure'},
                        ]}/>
                        <BooleanInput defaultValue={false} source={"required"} label={"Obligatoire"}/>
                        <FormDataConsumer>
                            {({
                                  formData,
                                  scopedFormData,
                                  getSource,
                                  ...rest
                              }) => (scopedFormData.type === 'choix_simple' || scopedFormData.type === 'choix_multiple') ?
                                <ArrayInput record={scopedFormData} source={getSource('questionChoix')}
                                            label={"Question choix réponses"} {...rest}>
                                    <SimpleFormIterator inline disableReordering
                                                        getItemLabel={index => `#${index + 1}`}>
                                        <TextInput record={scopedFormData} source='libelle'/>
                                        <NumberInput record={scopedFormData} source="ordre"/>
                                    </SimpleFormIterator>
                                </ArrayInput>
                                : null
                            }
                        </FormDataConsumer>
                    </SimpleFormIterator>
                </ArrayInput>
            </TabbedForm.Tab>
        </TabbedForm>
    </Create>
);