import {CreateGuesser, EditGuesser, FieldGuesser, InputGuesser, ListGuesser, ShowGuesser} from "@api-platform/admin";
import {Typography} from "@mui/material";
import {Link, useParams} from 'react-router-dom';
import {
    List,
    Datagrid,
    DateField,
    ReferenceManyField,
    ReferenceManyCount,
    TextField,
    BooleanField,
    ArrayField,
    SingleFieldList,
    FunctionField,
    ChipField,
    EditButton,
    ReferenceField,
    BulkDeleteButton,
    Button,
    useResourceContext,
    TopToolbar,
    SelectColumnsButton,
    CreateButton,
    ExportButton,
    FilterButton,
    DatagridConfigurable,
    TextInput,
    BooleanInput,
    DateInput,
    ReferenceInput,
    AutocompleteInput,
    ShowButton,
    SearchInput,
    FormDataConsumer,
    required,
    ImageField,
    ImageInput,
    useDataProvider, useRefresh, ListButton
} from "react-admin";
import { Admin, Resource, useEditContext, useShowContext, useRecordContext, TabbedForm, Show, TabbedShowLayout } from 'react-admin';
import ListeDroits from "./communaute_droits.tsx";
import { ColorField, ColorInput } from 'react-admin-color-picker';
import {useWatch} from "react-hook-form";
import {CibleInput, CommunauteInput} from "../components/inputs";
import {useMutation} from "react-query";
import SendIcon from "@mui/icons-material/Send";
import React from "react";

export const EvenementList = props => {
    const { communauteId } = useParams();
    return (
        <List {...props}
            resource="evenements"
            filter={communauteId !== undefined ? { communaute: communauteId } : undefined}
        >
            <DatagridConfigurable rowClick="edit">
                <ReferenceField reference={"communautes"} source={"communaute"} label={"Communauté"}>
                    <TextField source="titre"/>
                </ReferenceField>
                {/*<FieldGuesser source={"cible"} />*/}
                <ReferenceField reference={"evenement_categories"} source={"categorie"} label={"Catégorie"}>
                    <TextField source="libelle"/>
                </ReferenceField>
                <FieldGuesser source={"titre"} />
                {/*<FieldGuesser source={"contenu"} />*/}
                <FieldGuesser source={"etat"} />
                <FieldGuesser source={"accepteParticipation"} />
                {/*<FieldGuesser source={"accepteLike"} />*/}
                {/*<FieldGuesser source={"accepteCommentaire"} />*/}
                {/*<FieldGuesser source={"dateProgrammation"} />*/}
                <FieldGuesser source={"dateDebut"} />
                <FieldGuesser source={"dateFin"} />
                {/*<FieldGuesser source={"datesNotification"} />*/}
                {/*<FieldGuesser source={"adresse"} />*/}
                {/*<FieldGuesser source={"codePostal"} />*/}
                {/*<FieldGuesser source={"ville"} />*/}
                {/*<FieldGuesser source={"pays"} />*/}
                {/*<FieldGuesser source={"latitude"} />*/}
                {/*<FieldGuesser source={"longitude"} />*/}
                <FieldGuesser source={"createdAt"} />
                <FieldGuesser source={"auteur"} />
            </DatagridConfigurable>
        </List>
    );
}

const CategorieInput = props => {
    const communaute = useWatch({name: 'communaute'});
    return (
        <FormDataConsumer>
            {({formData, ...rest}) => (communaute !== undefined && communaute !== null) &&
                <ReferenceInput reference="evenement_categories" filter={{communaute: communaute}} {...props}>
                    <AutocompleteInput
                        optionText={(choice) =>
                            choice?.id // the empty choice is { id: '' }
                                ? `${choice.libelle}`
                                : ''
                        }
                        name={"categorie"}/>
                </ReferenceInput>
            }
        </FormDataConsumer>
    )
};

export const EvenementCreate = props => (
    <CreateGuesser {...props}>
            <CommunauteInput source={"communaute"} validate={[required()]} />
            <CategorieInput source={"categorie"} validate={[required()]} />
            <CibleInput source={"cible"} />
            <TextInput source={"titre"} validate={[required()]} />
            <TextInput source={"contenu"} multiline={true} rows={10} fullWidth={true} validate={[required()]} />
            {/* on enleve les like et le commentaires
            <BooleanInput source={"accepteLike"} />
            <BooleanInput source={"accepteCommentaire"} />*/}
            <BooleanInput source={"accepteParticipation"} />
            <DateInput source={"dateDebut"} validate={[required()]} />
            <DateInput source={"dateFin"} validate={[required()]} />
            <DateInput source={"dateProgrammation"} />
            <InputGuesser source={"datesNotification"} />
            <InputGuesser source={"adresse"} />
            <InputGuesser source={"codePostal"} />
            <InputGuesser source={"ville"} />
            <InputGuesser source={"pays"} />
            <InputGuesser source={"latitude"} />
            <InputGuesser source={"longitude"} />
        <ImageInput multiple={false}
                    name={"couvertureFile"}
                    label="Ajouter une image de couverture"
                    accept="image/*">
            <ImageField source="src"/>
        </ImageInput>
            <ImageInput multiple={true}
                        source="medias"
                        label="Related pictures"
                        accept="image/*">
                <ImageField source="src" />
            </ImageInput>
    </CreateGuesser>
);

export const EvenementShow = props => (
        <ShowGuesser {...props} /*aside={<Aside />}*/>
            <TabbedShowLayout>
                <TabbedShowLayout.Tab
                    label="Résumé">
                    <ReferenceField reference={"communautes"} source={"communaute"} label={"Communauté"}>
                        <TextField source="titre"/>
                    </ReferenceField>
                    <FieldGuesser source={"cible"} />
                    <FieldGuesser source={"titre"} />
                    <FieldGuesser source={"contenu"} />
                    <FieldGuesser source={"etat"} />
                    <BooleanField source={"accepteParticipation"} />
                    {/*on enleve les like et le commentaires
                <FieldGuesser source={"accepteLike"} />
                <FieldGuesser source={"accepteCommentaire"} />*/}
                    <FieldGuesser source={"dateProgrammation"} />
                    <FieldGuesser source={"dateDebut"} />
                    <FieldGuesser source={"dateFin"} />
                    <FieldGuesser source={"datesNotification"} />
                    <FieldGuesser source={"auteur"} />
                    <FieldGuesser source={"categorie"} />
                    <FieldGuesser source={"adresse"} />
                    <FieldGuesser source={"codePostal"} />
                    <FieldGuesser source={"ville"} />
                    <FieldGuesser source={"pays"} />
                    <FieldGuesser source={"latitude"} />
                    <FieldGuesser source={"longitude"} />
                    <ImageField label={"Image de couverture"} source={"couverture"}/>
                    <ReferenceManyField
                        reference="evenement_media"
                        target="evenement"
                        // sort={{ field: 'created_at', order: 'DESC' }}
                    >
                        <Datagrid>
                            <ImageField source="src" />
                            {/*<EditButton />*/}
                        </Datagrid>
                    </ReferenceManyField>
                    <FieldGuesser source={"createdAt"} />
                </TabbedShowLayout.Tab>
                <TabbedShowLayout.Tab
                    label="Participations"
                    path="participations">
                    <ReferenceManyField reference="participations" target="resourceIri"  label={false}>
                        <Datagrid>
                            <FieldGuesser source={"utilisateur"}/>
                        </Datagrid>
                    </ReferenceManyField>
                </TabbedShowLayout.Tab>
            </TabbedShowLayout>
        </ShowGuesser>
);



const PublierButton = () => {
    const record = useRecordContext();
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const { mutate, isLoading } = useMutation(
        () => dataProvider.update(
            `evenements`,
            { id: record.id, data: { etat: 'pretPublier'}}
        ).then(
            () => refresh()
        )
    );
    return <Button color="primary" size={"small"} hildren={SendIcon} label="Publier" onClick={() => mutate()} disabled={isLoading}/>;
};

const ValiderButton = () => {
    const record = useRecordContext();
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const { mutate, isLoading } = useMutation(
        () => dataProvider.update(
            `evenements`,
            { id: record.id, data: { etat: 'valider'}}
        ).then(
            () => refresh()
        )
    );
    return <Button
        color="primary"
        size={"small"}
        hildren={SendIcon}
        label="Valider"
        onClick={() => mutate()}
        disabled={isLoading} />
        ;
};

const PlanifierButton = () => {
    const record = useRecordContext();
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const { mutate, isLoading } = useMutation(
        () => dataProvider.update(
            `evenements`,
            { id: record.id, data: { etat: 'planifier'}}
        ).then(
            () => refresh()
        )
    );
    return <Button
        color="primary"
        size={"small"}
        hildren={SendIcon}
        label="Planifier"
        onClick={() => mutate()}
        disabled={isLoading} />;
};

const ArchiverButton = () => {
    const record = useRecordContext();
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const { mutate, isLoading } = useMutation(
        () => dataProvider.update(
            `evenements`,
            { id: record.id, data: { etat: 'archiver'}}
        ).then(
            () => refresh()
        )
    );
    return <Button
        color="primary"
        size={"small"}
        hildren={SendIcon}
        label="Archiver"
        onClick={() => mutate()}
        disabled={isLoading} />;
};

const SupprimerCouvertureBouton = () => {
    const record = useRecordContext();
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const {mutate, isLoading} = useMutation(
        () => dataProvider.update(
            `evenements`,
            {id: record.id, data: {deleteCouverture: true}, previousData: record}
        ).then(
            () => refresh()
        )
    );

    return <Button
        color="primary"
        size={"small"}
        sx={{mb: 3}}
        onClick={() => mutate()}
        disabled={isLoading}> Supprimer l'image de couverture</Button>;
};

const EvenementEditActions = () => {
    const record = useRecordContext();
    return (
        <TopToolbar>
            <ShowButton/>
            {/* Add your custom actions */}
            <ListButton/>
            { record !== undefined && record.transitions.includes('pretPublier') && (<PublierButton/>)}
            { record !== undefined && record.transitions.includes('archiver') && (<ArchiverButton/>)}
            { record !== undefined && record.datePlanification !== undefined && record.transitions.includes('planifier') && (<PlanifierButton/>)}
            { record !== undefined && record.transitions.includes('valider') && (<ValiderButton/>)}
        </TopToolbar>
    );
}

export const EvenementEdit = props => (
    <EditGuesser {...props} actions={<EvenementEditActions/>}>
        <CommunauteInput source={"communaute"} validate={[required()]} />
        <CategorieInput source={"categorie"} validate={[required()]} />
        <CibleInput source={"cible"} />
        <TextInput source={"titre"} validate={[required()]} />
        <TextInput source={"contenu"} multiline={true} rows={10} fullWidth={true} validate={[required()]} />
        {/*on enleve les like et le commentaires
        <BooleanInput source={"accepteLike"} />
        <BooleanInput source={"accepteCommentaire"} />*/}
        <BooleanInput source={"accepteParticipation"} />
        <DateInput source={"dateDebut"} validate={[required()]} />
        <DateInput source={"dateFin"} validate={[required()]} />
        <DateInput source={"dateProgrammation"} />
        <InputGuesser source={"datesNotification"} />
        <InputGuesser source={"adresse"} />
        <InputGuesser source={"codePostal"} />
        <InputGuesser source={"ville"} />
        <InputGuesser source={"pays"} />
        <InputGuesser source={"latitude"} />
        <InputGuesser source={"longitude"} />
        <ImageInput multiple={true}
                    source="medias"
                    label="Related pictures"
                    accept="image/*">
            <ImageField source="src" />
        </ImageInput>
        <ImageInput multiple={false}
                    name={"couvertureFile"}
                    label="Changer d'image de couverture"
                    accept="image/*"
        >
            <ImageField source="src"/>
        </ImageInput>
        <ImageField source={"couverture"}/>
        <FormDataConsumer>
            {({
                  formData,
                  scopedFormData,
                  getSource,
                  ...rest
              }) => (formData.couverture !== null) ? <SupprimerCouvertureBouton/> : null}

        </FormDataConsumer>
    </EditGuesser>
);