import {
    ArrayField, ChipField,
    Datagrid,
    DatagridConfigurable, DateField, DeleteWithConfirmButton,
    List, ReferenceField, Show, ShowController, ShowView,
    SimpleList,
    SimpleShowLayout, SingleFieldList,
    TextField, TimeInput, useListContext,
    useShowContext, useShowController
} from "react-admin";
import {FieldGuesser, ShowGuesser} from "@api-platform/admin";
import React, {useEffect, useState} from "react";
import * as PropTypes from "prop-types";


export const SondageReponseList = props => {
    return (
        <List {...props} sort={{field: 'createdAt', order: 'DESC'}}>
            <DatagridConfigurable rowClick={"show"}>
                <FieldGuesser source={"sondage"}/>
                <FieldGuesser source={"utilisateur"}/>
                <FieldGuesser source={"createdAt"}/>
            </DatagridConfigurable>
        </List>
    )
}

export const SondageReponseShow = props => {

    return (
        <Show {...props}>
            <SimpleShowLayout  sx={{p : 3}}>
                <FieldGuesser source={"sondage"}/>
                <FieldGuesser source={"utilisateur"}/>
                <FieldGuesser sx={{mb:5}} source={"createdAt"}/>
                <ArrayField  source="sondageReponseQuestions" label={"Liste réponses aux questions"}>
                    <Datagrid sx={{mt: 4}} bulkActionButtons={false}>
                        <TextField source={"sondageQuestion.type"} label={'Type de question'}/>
                        <TextField source={"sondageQuestion.question"} label={"Libellé question"}/>
                        <TextField source={"reponse"} label={"Réponse"}/>
                        <DateField showDate showTime={false} source={"date"} label={"Réponse date"}/>
                        <DateField showTime showDate={false} source={"heure"} label={"Réponse heure"}/>
                        <ArrayField source={"sondageReponseChoix"}>
                            <SimpleList
                                primaryText={record => record.libelleReponseChoix}
                                secondaryText={record => "Coché : " + record.coche.toString()}
                                />
                        </ArrayField>
                    </Datagrid>
                </ArrayField>
                <DeleteWithConfirmButton
                    confirmContent="La réponse à ce sondage sera définitivement supprimée. Etes-vous sûre?"
                />
            </SimpleShowLayout>
        </Show>
    );
}
