import {CreateGuesser, EditGuesser, FieldGuesser, InputGuesser, ListGuesser, ShowGuesser} from "@api-platform/admin";
import {
    Edit,
    BooleanInput,
    AutocompleteInput,
    BooleanField,
    Count,
    SimpleForm,
    DatagridConfigurable,
    DateField,
    DateInput,
    ExportButton,
    FilterButton,
    List,
    Show,
    NullableBooleanInput,
    NumberField,
    ReferenceField,
    ReferenceInput,
    SearchInput,
    SelectColumnsButton,
    TextField,
    TextInput,
    SelectInput,
    TopToolbar,
    useListContext,
    ShowButton,
    ListButton,
    useRecordContext,
    useUpdate,
    useDataProvider,
    Datagrid,
    CreateButton,
    EditButton,
    Button,
    ReferenceManyField,
    ImageField,
    ImageInput, FormDataConsumer, Labeled,
    TabbedShowLayout, ReferenceArrayInput, useRefresh,
    required
} from 'react-admin';
import { useMutation } from 'react-query';
import {Typography} from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import {useWatch} from "react-hook-form";
import {CibleInput, CommunauteInput, UtilisateurInput} from "../components/inputs";
import {useParams} from "react-router-dom";
import React from "react";

const PublicationListActions = () => (
    <TopToolbar>
        <SelectColumnsButton />
        <FilterButton />
        <CreateButton />
        <ExportButton />
    </TopToolbar>
);

const filters = [
    <SearchInput source="q" alwaysOn />,
    <CommunauteInput source="communaute" />,
    <SelectInput source="etat" name={"etat"} field={"etat"} choices={[
        { id: 'brouillon', name: 'Brouillon' },
        { id: 'planifie', name: 'Planifié' },
        { id: 'attente_publication', name: 'Attente publication' },
        { id: 'attente_validation', name: 'Attente validation' },
        { id: 'publie', name: 'Publié' },
        { id: 'archive', name: 'Archivé' },
    ]} />,
    <SelectInput source="type" name={"type"} field={"type"} choices={[
        { id: 'simple', name: 'Simple' },
        { id: 'alerte', name: 'Alerte' },
    ]} />,
    <UtilisateurInput source="auteur" />,
];

const rowClick = (id, resource, record) => {
    return 'show';
};

export const EmbedPublicationList = props => {
    const { communauteId } = useParams();
    return (
        <List {...props}
              resource="publications"
              filters={filters}
              filter={{communaute: communauteId}}
              actions={<PublicationListActions />}
              sort={{ field: 'createdAt', order: 'DESC' }}>
            <DatagridConfigurable rowClick={rowClick}>
                <ReferenceField reference={"communautes"} source={"communaute"} label={"Communauté"}>
                    <TextField source="titre"/>
                </ReferenceField>
                <TextField source={"titre"} />
                <TextField source={"etat"} />
                <TextField source={"type"} />
                <BooleanField source={"accepteLike"} />
                <BooleanField source={"accepteCommentaire"} />
                <DateField source={"dateProgrammation"} />
                <DateField source={"dateFinPublication"} />
                <FieldGuesser source={"auteur"} label={"Auteur"} />
                <DateField source={"publishedAt"} label={"Date de publication"}/>
                <DateField source={"createdAt"} label={"Date création"} />
                {/*<ShowButton />*/}
                <EditButton />
            </DatagridConfigurable>
        </List>
    );
}

export const PublicationList = props => {
    return (
        <List {...props}
              resource="publications"
              filters={filters}
              actions={<PublicationListActions />}
              sort={{ field: 'createdAt', order: 'DESC' }}>
            <DatagridConfigurable rowClick={rowClick}>
                <ReferenceField reference={"communautes"} source={"communaute"} label={"Communauté"}>
                    <TextField source="titre"/>
                </ReferenceField>
                {/*<FieldGuesser source={"cible"} />*/}
                <TextField source={"titre"} />
                <TextField source={"etat"} />
                <TextField source={"type"} />
                <BooleanField source={"accepteLike"} />
                <BooleanField source={"accepteCommentaire"} />
                <DateField source={"dateProgrammation"} />
                <DateField source={"dateFinPublication"} />
                <FieldGuesser source={"auteur"} label={"Auteur"} />
                <DateField source={"publishedAt"} label={"Date de publication"}/>
                <DateField source={"createdAt"} label={"Date création"} />
                {/*<ShowButton />*/}
                <EditButton />
            </DatagridConfigurable>
        </List>
    );
}

const Aside = () => (
    <div style={{ width: 200, margin: '1em' }}>
            <Typography variant="h6">Détail de publication</Typography>
            <Typography variant="body2">
                    Exemple de panneau latéral
            </Typography>
    </div>
);

const PublicationTitle = () => {
    const record = useRecordContext();
    // the record can be empty while loading
    if (!record) return null;
    return <span>Publication "{record.titre}"</span>;
};

const MyPublicationShow = props => {
    return (
        <TabbedShowLayout>
            <TabbedShowLayout.Tab
                label="Résumé">
                <FieldGuesser source={"communaute"} />
                <FieldGuesser source={"auteur"} />
                <FieldGuesser source={"cible"} />
                <FieldGuesser source={"titre"} />
                <FieldGuesser source={"contenu"} />
                <FieldGuesser source={"etat"} />
                <FieldGuesser source={"type"} />
                <FieldGuesser source={"accepteLike"} />
                <FieldGuesser source={"accepteCommentaire"} />
                <FieldGuesser source={"dateProgrammation"} />
                <FieldGuesser source={"dateFinPublication"} />
                <ImageField label={"Image de couverture"} source={"couverture"}/>
                {/*<FieldGuesser source={"medias"} />*/}
                <ReferenceManyField
                    reference="publication_media"
                    target="publication"
                    // sort={{ field: 'created_at', order: 'DESC' }}
                >
                    <Datagrid>
                        <ImageField source="src" />
                        {/*<EditButton />*/}
                    </Datagrid>
                </ReferenceManyField>
            </TabbedShowLayout.Tab>
            <TabbedShowLayout.Tab
                label="Likes"
                path="likes">
                <ReferenceManyField reference="likes" target="resourceIri"  label={false}>
                    <Datagrid>
                        <FieldGuesser source={"utilisateur"}/>
                    </Datagrid>
                </ReferenceManyField>
            </TabbedShowLayout.Tab>
            <TabbedShowLayout.Tab
                label="Commentaires"
                path="commentaires">
                <ReferenceManyField reference="commentaires" target="resourceIri"  label={false}>
                    <Datagrid>
                        <FieldGuesser source={"contenu"}/>
                        <FieldGuesser source={"utilisateur"}/>
                    </Datagrid>
                </ReferenceManyField>
            </TabbedShowLayout.Tab>
        </TabbedShowLayout>
    )};

export const PublicationShow = props => (
    <ShowGuesser {...props} /*aside={<Aside />}*/>
        <MyPublicationShow />
    </ShowGuesser>
);

const PublierButton = () => {
        const record = useRecordContext();
        const dataProvider = useDataProvider();
        const refresh = useRefresh();
        const { mutate, isLoading } = useMutation(
                () => dataProvider.update(
                    `publications`,
                    { id: record.id, data: { etat: 'pretPublier'}}
                ).then(
                        () => refresh()
                )
        );
        return <Button color="primary" size={"small"} hildren={SendIcon} label="Publier" onClick={() => mutate()} disabled={isLoading}/>;
};

const ValiderButton = () => {
    const record = useRecordContext();
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const { mutate, isLoading } = useMutation(
        () => dataProvider.update(
            `publications`,
            { id: record.id, data: { etat: 'valider'}}
        ).then(
            () => refresh()
        )
    );
    return <Button
            color="primary"
            size={"small"}
            hildren={SendIcon}
            label="Valider"
            onClick={() => mutate()}
            disabled={isLoading} />
    ;
};

const PlanifierButton = () => {
    const record = useRecordContext();
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const { mutate, isLoading } = useMutation(
        () => dataProvider.update(
            `publications`,
            { id: record.id, data: { etat: 'planifier'}}
        ).then(
            () => refresh()
        )
    );
    return <Button
            color="primary"
            size={"small"}
            hildren={SendIcon}
            label="Planifier"
            onClick={() => mutate()}
            disabled={isLoading} />;
};

const ArchiverButton = () => {
        const record = useRecordContext();
        const dataProvider = useDataProvider();
        const refresh = useRefresh();
        const { mutate, isLoading } = useMutation(
            () => dataProvider.update(
                `publications`,
                { id: record.id, data: { etat: 'archiver'}}
            ).then(
                () => refresh()
            )
        );
        return <Button
                color="primary"
                size={"small"}
                hildren={SendIcon}
                label="Archiver"
                onClick={() => mutate()}
                disabled={isLoading} />;
};

const SupprimerCouvertureBouton = () => {
    const record = useRecordContext();
    const dataProvider = useDataProvider();
    const refresh = useRefresh();
    const {mutate, isLoading} = useMutation(
        () => dataProvider.update(
            `publications`,
            {id: record.id, data: {deleteCouverture: true}, previousData: record}
        ).then(
            () => refresh()
        )
    );


    return <Button
        color="primary"
        size={"small"}
        sx={{mb: 3}}
        onClick={() => mutate()}
        disabled={isLoading}> Supprimer l'image de couverture</Button>;
};

const PublicationEditActions = () => {
        const record = useRecordContext();
        return (
            <TopToolbar>
                    <ShowButton/>
                    {/* Add your custom actions */}
                    <ListButton/>
                    { record !== undefined && record.transitions.includes('pretPublier') && (<PublierButton/>)}
                    { record !== undefined && record.transitions.includes('archiver') && (<ArchiverButton/>)}
                    { record !== undefined && record.datePlanification !== undefined && record.transitions.includes('planifier') && (<PlanifierButton/>)}
                    { record !== undefined && record.transitions.includes('valider') && (<ValiderButton/>)}
            </TopToolbar>
        );
}

export const PublicationEdit = props => (
    <Edit {...props} actions={<PublicationEditActions/>}>
        <SimpleForm>
            <CommunauteInput source="communaute" />
            <CibleInput source="cible" />
            <SelectInput source="type" name={"type"} field={"type"} defaultValue={"simple"} choices={[
                { id: 'simple', name: 'Simple' },
                { id: 'alerte', name: 'Alerte' },
            ]} />
            <TextInput source={"titre"} fullWidth />
            <TextInput source={"contenu"} multiline={true} rows={10} fullWidth={true} />
            <TextField source={"etat"} />
            <BooleanInput source={"accepteLike"} />
            <BooleanInput source={"accepteCommentaire"} />
            <DateInput source={"dateProgrammation"} />
            <DateInput source={"dateFinPublication"} />
            <ImageInput multiple={true}
                        source="medias"
                        label="Related pictures"
                        accept="image/*">
                <ImageField source="src" />
            </ImageInput>
            <ImageInput multiple={false}
                        name={"couvertureFile"}
                        label="Changer d'image de couverture"
                        accept="image/*"
            >
                <ImageField source="src"/>
            </ImageInput>
            <ImageField source={"couverture"}/>
            <FormDataConsumer>
                {({
                      formData,
                      scopedFormData,
                      getSource,
                      ...rest
                  }) => (formData.couverture !== null) ? <SupprimerCouvertureBouton/> : null}

            </FormDataConsumer>
        </SimpleForm>
    </Edit>
);

export const PublicationCreate = props => (
    <CreateGuesser {...props}>
        <CommunauteInput source="communaute" validate={[required()]} />
        <CibleInput source="cible" />
        <TextInput source={"titre"} validate={[required()]} fullWidth />
        <TextInput source={"contenu"} validate={[required()]} multiline={true} rows={10} fullWidth={true} />
        <SelectInput source="type" name={"type"} field={"type"} defaultValue={"simple"} choices={[
                { id: 'simple', name: 'Simple' },
                { id: 'alerte', name: 'Alerte' },
        ]} />
        <InputGuesser source={"accepteLike"} />
        <InputGuesser source={"accepteCommentaire"} />
        <InputGuesser source={"dateProgrammation"} />
        <InputGuesser source={"dateFinPublication"} />
        <ImageInput multiple={true}
                    source="medias"
                    label="Related pictures"
                    accept="image/*">
            <ImageField source="src" />
        </ImageInput>
        <ImageInput multiple={false}
                    name={"couvertureFile"}
                    label="Ajouter une image de couverture"
                    accept="image/*">
            <ImageField source="src"/>
        </ImageInput>
    </CreateGuesser>
);