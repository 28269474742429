import {CreateGuesser, EditGuesser, FieldGuesser, InputGuesser, ListGuesser, ShowGuesser} from "@api-platform/admin";
import HorizontalRule from '@tiptap/extension-horizontal-rule';
import {
    AutocompleteInput,
    BooleanField,
    Count,
    DatagridConfigurable,
    DateField,
    DateInput,
    ExportButton,
    FilterButton,
    List,
    Show,
    NullableBooleanInput,
    NumberField,
    ReferenceField,
    ReferenceInput,
    SearchInput,
    SelectColumnsButton,
    TextField,
    TextInput,
    SelectInput,
    TopToolbar,
    useListContext,
    ShowButton,
    ListButton,
    useRecordContext,
    useUpdate,
    useDataProvider, Datagrid, CreateButton, EditButton,
    FileField, FileInput, ImageField, ImageInput
} from 'react-admin';
import { useMutation } from 'react-query';
import {
    DefaultEditorOptions,
    RichTextInput,
    RichTextInputToolbar,
    LevelSelect,
    FormatButtons,
    AlignmentButtons,
    ListButtons,
    LinkButtons,
    QuoteButtons,
    ClearButtons,
    useTiptapEditor,
} from 'ra-input-rich-text';
import {Button, Typography} from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import React from "react";

export const PublicationMediaList = props => (
    <ListGuesser {...props} rowClick={"edit"}>
        <ReferenceField reference={"publications"} source={"publication"} label={"Publications"}>
            <TextField source="titre"/>
        </ReferenceField>
        <ImageField source="src" label={"Image"} />
        {/*<FieldGuesser source={"filename"} />*/}
        {/*<FieldGuesser source={"file"} />*/}
        {/*<FieldGuesser source={"communauteResource"} />*/}
    </ListGuesser>
);

export const PublicationMediaShow = props => (
    <ShowGuesser {...props}>
        <ReferenceField reference={"publications"} source={"publication"} label={"Publications"}>
            <TextField source="titre"/>
        </ReferenceField>
        <ImageField source="src" label={"Image"} />
        {/*<FieldGuesser source={"filename"} />        */}
        {/*<FieldGuesser source={"file"} />        */}
        {/*<FieldGuesser source={"communauteResource"} />    */}
    </ShowGuesser>
);

export const PublicationMediaCreate = props => (
    <CreateGuesser {...props}>
        <InputGuesser source={"publication"} />
        {/*<InputGuesser source={"filename"} />*/}
        <ImageInput source="file">
            <ImageField source="src" title="title" />
        </ImageInput>
        {/*<InputGuesser source={"communauteResource"} />*/}
    </CreateGuesser>
);