import {CreateGuesser, EditGuesser, FieldGuesser, InputGuesser, ListGuesser, ShowGuesser} from "@api-platform/admin";
import {
    AutocompleteInput,
    BooleanField,
    Count,
    DatagridConfigurable,
    DateField,
    DateInput,
    ExportButton,
    FilterButton,
    List,
    NullableBooleanInput,
    NumberField,
    ReferenceField,
    ReferenceInput,
    SearchInput,
    SelectColumnsButton,
    TextField,
    TextInput,
    SelectInput,
    NumberInput,
    TopToolbar,
    useListContext,
    ShowButton,
    ListButton,
    useRecordContext,
    useUpdate,
    useDataProvider, Datagrid, CreateButton, ImageField, ReferenceManyField, ImageInput, minValue, maxValue, DateTimeInput
} from 'react-admin';
import { ColorField, ColorInput } from 'react-admin-color-picker';
import {useController, useWatch} from "react-hook-form";
import moment from "moment";
import React from "react";

const filters = [
    <SearchInput source="q" alwaysOn />,
    <ReferenceInput source="communaute_id" reference="communautes" name={"Communaute"}>
        <AutocompleteInput
            optionText={(choice) =>
                choice?.id // the empty choice is { id: '' }
                    ? `${choice.titre}`
                    : ''
            }
            name={"communaute"}/>
    </ReferenceInput>,
    <TextInput source={"titre"} />,
    <TextInput source={"type"} />,
];

export const IndicateurList = props => (
    <List {...props} filters={filters}>
        <Datagrid rowClick={"show"}>
            <ReferenceField reference={"communautes"} source={"communaute"} label={"Communauté"}>
                <TextField source="titre"/>
            </ReferenceField>
            <FieldGuesser source={"titre"} />
            <FieldGuesser source={"type"} />
            <FieldGuesser source={"data"} />
            <ColorField source={"couleur"} />
        </Datagrid>
    </List>
);

const TypeInput = props => (
    <SelectInput name={"type"} field={"type"} choices={[
        { id: 'compte_a_rebours', name: 'Compte à rebours' },
        { id: 'date_depuis', name: 'Date depuis' },
        { id: 'texte', name: 'Texte' },
    ]} {...props} />
);

const DataButton = props => {
    const type = useWatch({ name: 'type' });

    if (type === 'date_depuis') {
        return (
            // <DateInput name={"data"} source={"data"} />
            <DateInput name={"data"} source={"data"} inputProps={{
                max: moment().format("YYYY-MM-DD"),
            }} validate={maxValue(moment().format("YYYY-MM-DD"))} />
        );
    }

    if (type === 'texte') {
        return (
            <TextInput source="data" />
        );
    }

    if (type === 'compte_a_rebours') {
        return (
            // <DateInput name={"data"} source={"data"} />
            <DateTimeInput name={"data"} source={"data"} inputProps={{
                min: moment().format("YYYY-MM-DDThh:mm"),
            }} validate={minValue(moment().format("YYYY-MM-DDThh:mm"))} />
        );
    }

    return null;
}

export const IndicateurShow = props => (
    <ShowGuesser {...props}>
        <FieldGuesser source={"communaute"} />
        <FieldGuesser source={"titre"} />
        <FieldGuesser source={"type"} />
        <FieldGuesser source={"data"} />
        <ColorField source={"couleur"} />
    </ShowGuesser>
);

export const IndicateurCreate = props => (
    <CreateGuesser {...props}>
        <ReferenceInput source="communaute_id" reference="communautes" name={"Communaute"}>
            <AutocompleteInput
                optionText={(choice) =>
                    choice?.id // the empty choice is { id: '' }
                        ? `${choice.titre}`
                        : ''
                }
                name={"communaute"}/>
        </ReferenceInput>
        <InputGuesser source={"titre"} />
        <TypeInput source={"type"} />
        <DataButton source={"data"} />
        <ColorField source={"couleur"} />
        <ColorInput source={"couleur"} picker="Sketch" />
    </CreateGuesser>
);

export const IndicateurEdit = props => (
    <EditGuesser {...props}>
        <ReferenceInput source="communaute_id" reference="communautes" name={"Communaute"}>
            <AutocompleteInput
                optionText={(choice) =>
                    choice?.id // the empty choice is { id: '' }
                        ? `${choice.titre}`
                        : ''
                }
                name={"communaute"} disabled />
        </ReferenceInput>
        <InputGuesser source={"titre"} />
        <TypeInput source={"type"} />
        <DataButton source={"data"} />
        <ColorInput source={"couleur"} picker="Sketch" />
    </EditGuesser>
);